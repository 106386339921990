import Button from "react-bootstrap/Button";
import ModalPopup from "../Common/ModalPopup";
import { FaThumbsUp } from "react-icons/fa";
import { RiDraftFill } from "react-icons/ri";
import "./NewTourPlan.css";
import React, { useState, useEffect } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FiCopy } from "react-icons/fi";
import { addNewTourPlanApi } from "../../services/MonthlyTourPlans/addNewTourPlanApi";
import { addNewTourWssApi } from "../../services/MonthlyTourPlans/addNewTourWssApi";
import UpcomingTourPlans from "./UpcomingTourPlans";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useSelector, connect, useDispatch } from "react-redux";
import Loader from "../Common/Loader";
import CommonSnackbar from "../Common/CommonSnackbar";
import { Autocomplete, TextField } from "@mui/material";
import { getUniqueListBy } from "../Common/Function";
import {
  clusterCodePlaceHolder,
  primaryObjectivesOption,
  tourTypeOption,
} from "../Common/Constant";
import { getRmdmWssApi } from "../../services/rmdm/getRmdmWssApi";
import { getZSMTerritoryApi } from "../../services/zsm/getZSMTerritoryApi";
import { getZSMWssNameApi } from "../../services/zsm/getZSMWssNameApi";
import { BsInfoCircle } from "react-icons/bs";
import { getTownApi } from "../../services/wss/getTownApi";
import { getCustomerCodeForTownApi } from "../../services/Town/getCustomerCodeForTownApi";

const rmdmTownPlaceHolder = [
  {
    attributes: {
      type: "",
      url: "",
    },
    Id: "",
    CustomerName__c: "",
    WSS_Territory_Code__c: "",
    Customer_Code__c: "",
    Town__c: "",
    WSS_latitude__c: "",
    WSS_longitude__c: "",
    SH3_Code__c: "",
    SH3_Name__c: "",
  },
];

const rmdmClusterPlaceholder = [
  {
    attributes: {
      type: "",
      url: "",
    },
    Id: "",
    SH3_Email__c: "",
    CLUSTER_CODE__c: "",
    Cluster_Description__c: "",
    SH3_Code__c: "",
    SH3_Name__c: "",
  },
];

const rmdmWssPlaceHolder = [
  {
    attributes: {
      type: "",
      url: "",
    },
    Id: "",
    CustomerName__c: "",
    Customer_Code__c: "",
    WSS_Territory_Code__c: "",
    Town__c: "",
    WSS_latitude__c: "",
    WSS_longitude__c: "",
  },
];

const territoryPlaceHolder = [
  {
    attributes: {},
    Id: "",
    SH2_Name__c: "",
    SH2_Code__c: "",
    Name: "",
    SH3_Code__c: "",
  },
];

const NewTour = ({
  addNewTourPlanApi,
  addNewTourWssApi,
  getRmdmWssApi,
  getZSMTerritoryApi,
  getZSMWssNameApi,
}) => {
  const [inputList, setInputList] = useState([
    {
      id: 0,
      // date0: "",
      recordType: "Monthly Tour Plan",
      wssKey: "wss0",
    },
  ]);

  const dispatch = useDispatch();
  const [cluster, setCluster] = useState(rmdmClusterPlaceholder);
  const [wssOption, setWssOption] = useState([]);
  const [wssRmdmOption, setWssRmdmOption] = useState(rmdmWssPlaceHolder);
  const [selectedTerritory, setSelectedTerritory] = useState({});
  const [territoryName, setTerritoryName] = useState();
  const [wssSelectedName, setWssSelectedName] = useState([]);
  const [townSelectedName, setTownSelectedName] = useState([]);
  const [allData, setAllData] = useState([]);
  const [sh2Name, setSh2Name] = useState([]);
  const [pidliteId, setPidliteId] = useState([]);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isDateValidModalOpen, setIsDateValidModalOpen] = useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState("");
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [picklistLoader, setPicklistLoader] = useState(false);
  const [showUpcomingTour, setShowUpcomingTour] = useState(false);
  const [wssIndex, setWssIndex] = useState();
  const [wssOptionList, setwssOptionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userWssArr, setUserWssArr] = useState([]);
  const [userTownArr, setUserTownArr] = useState([]);
  const [userPrimaryObjArr, setUserPrimaryObjArr] = useState([]);
  const [
    territoryAutoCompletePlaceHolder,
    setTerritoryAutoCompletePlaceHolder,
  ] = useState(["Select Territory"]);
  const [failedOpen, setFailesOpen] = useState(false);
  const [error, setError] = useState();
  const [index, setIndex] = useState(0);
  const [rmdmTown, setRmdmTown] = useState(rmdmTownPlaceHolder);
  const [independentWssOptions, setIndePendentWssOptions] = useState([]);
  const [
    independentWssOptionsForRbDmAndMH3,
    setIndependentWssOptionsForRbDmAndMH3,
  ] = useState([]);
  const [isChangedWssName, setIsChangedWssName] = useState({
    index: "",
    value: false,
  });
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [zmsWss, setZsmWss] = useState([]);
  const [territory, setTerritory] = useState(territoryPlaceHolder);
  const [showTextField, setShowTextField] = useState(false);
  const [otherPrimaryObj, setOtherPrimaryObj] = useState("");
  const [isTownData, setIsTownData] = useState("Loading");
  const [bmTownOptions, setBmTownOptions] = useState([]);
  const [hoTownOptions, setHoTownOptions] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  // to get territory options
  const territoryOption = useSelector(
    (state) => state?.territory?.territoryDetails
  );

  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );

  const townList = useSelector((state) => state?.rmdmTown?.townList);

  const Title = useSelector((state) =>
    state?.userType?.type != "Other"
      ? state?.userType?.type
      : state?.userDetails?.details?.Title
  );

  const upcomingTourPlansData = useSelector(
    (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records
  );

  const bmWssList = useSelector((state) => state?.bmWss?.wssList);

  console.log("userTownArr", userTownArr)

  const StringSplitterWss = (inputString) => {
    if (typeof inputString !== 'string' || inputString === null) {
      return [];
    } else {
      const modifiedSegments = [];
      var splitWithComma = inputString.split(",");
      if (splitWithComma.length <= 2) {
        const splitSegment = splitWithComma[0].split(':');
        if (splitSegment[0] != null && splitSegment[1] != null && splitSegment[2] != null) {
          const modifiedSegment = {
            "CustomerName__c": splitSegment[0],
            "Customer_Code__c": splitSegment[1],
            "CustomerGroup__c": splitSegment[2].replace(/[()]/g, ''),
            "Town__c": splitWithComma[1]
          };
          modifiedSegments.push(modifiedSegment);
          const result = modifiedSegments;
          return result;
        }
      } else {
        splitWithComma.forEach((segment, index) => {
          if (index % 2 === 0) {
            const splitSegment = segment.split(':');
            if (splitSegment[0] != null && splitSegment[1] != null && splitSegment[2] != null) {
              let result = splitSegment[2].replace(/[()]/g, "");
              const modifiedSegment = {
                "CustomerName__c": splitSegment[0],
                "Customer_Code__c": splitSegment[1],
                "CustomerGroup__c": result,
                "Town__c": splitWithComma[index + 1]
              };
              modifiedSegments.push(modifiedSegment);
            }
          }
        });
        const result = modifiedSegments;
        return result;
      }
    }
  };

  //on change of input feild
  const onChangeHandler = (e, index) => {
    setIndex(index);
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    if (e.target.name?.substring(0, 9) === "territory") {
      if (Title !== "SH3") {
        let newList = inputList;
        newList[index][`wss${index}`] = "";
        newList[index][`town${index}`] = "";
        setInputList(newList);
      }
      setSelectedTerritory({
        index: Number(e.target.name?.charAt(e.target.name.length - 1)),
        value: e?.target?.value,
      });
      setTerritoryName(e?.target?.name);
    }
    if (e.target.name?.substring(0, 3) === "wss") {
      let newList = inputList;
      newList[index][`town${index}`] = "";
      setInputList(newList);
      // setSelectedTerritory({
      //   index: Number(e.target.name?.charAt(e.target.name.length - 1)),
      //   value: e?.target?.value,
      // });
      wssSelectedName?.splice(
        e.target.name?.charAt(e.target.name.length - 1),
        1,
        e.target.value?.split(",")[0]
      );
      setWssSelectedName((fs) => [...fs]);
      // setWssName(e?.target?.name);
      setIsChangedWssName({
        index: Number(e.target.name?.charAt(e.target.name.length - 1)),
        value: true,
      });
      if (
        Title !== "MH3" &&
        Title !== "RBDM Collaborator" &&
        Title !== "HO Collaborator" && 
        Title !== "SH7"
      ) {
        townSelectedName?.splice(
          e.target.name?.charAt(e.target.name.length - 1),
          1,
          e.target.value?.split(",")[1]
        );
        setTownSelectedName((fs) => [...fs]);
      }
    }

    // if (e.target.name?.substring(0, 4) === "town") {
    //   // setIsChangedWssName({
    //   //   index: index,
    //   //   value: false,
    //   // });
    // }
  };
  // copy function
  const addFormHandler = (e) => {
    e.preventDefault();
    const date = `date${inputList?.length}`;
    const dateValue = `date${inputList?.length - 1}`;
    const wss = `wss${inputList?.length}`;
    const wssValue = `wss${inputList?.length - 1}`;
    const town = `town${inputList?.length}`;
    const townValue = `town${inputList?.length - 1}`;
    setInputList([
      ...inputList,
      {
        id: inputList?.length,
        [date]: inputList[inputList?.length - 1]?.[dateValue],
        recordType: "Monthly Tour Plan",
        wssKey: wss,
        [wss]: inputList[inputList?.length - 1]?.[wssValue],
        [town]: inputList[inputList?.length - 1]?.[townValue],
      },
    ]);
    setSelectedTerritory({
      index: selectedTerritory?.index + 1,
      value: selectedTerritory?.value,
    });
    setTerritoryName(`territory${selectedTerritory?.index + 1}`);
    let newData = territoryAutoCompletePlaceHolder;
    newData[newData?.length] = newData[newData?.length - 1];
    setTerritoryAutoCompletePlaceHolder(newData);
    if (
      Title === "MH3" ||
      Title === "RBDM Collaborator" ||
      Title == "HO Collaborator"
    ) {
      setCluster((ls) => [...ls, cluster[cluster?.length - 1]]);
      setWssRmdmOption((ls) => [
        ...ls,
        wssRmdmOption[wssRmdmOption?.length - 1],
      ]);
      setRmdmTown((ls) => [...ls, rmdmTown[rmdmTown?.length - 1]]);
    }
  };

  useEffect(() => {
    var wssCodes = StringSplitterWss(inputList[0].wss0)
    if (!_.isEmpty(wssCodes) && Title !== "HO Collaborator" && Title !== "MH3" && Title !== "RBDM Collaborator") {
      (async () => {
        setIsTownData("Loading")
        const res = await getTownApi(wssCodes);
          if (res?.success) {
            if (res?.data?.length === 0) {
              setIsTownData("false")
              setBmTownOptions(res?.data);
            } else {
              setBmTownOptions(res?.data);
              setIsTownData("true")
            }
          }
      })();
    }
  }, [inputList, wssSelectedName])

  const handleTerritoryPlaceHandler = (value, index) => {
    let tempData = territoryAutoCompletePlaceHolder;
    tempData[index] = value;
    setTerritoryAutoCompletePlaceHolder(tempData);
  };
  const wssOptionsFromRedux = useSelector(
    (state) => state?.wssOptionsData?.wssCodeList
  );
  const townOptionsFromRedux = useSelector(
    (state) => state?.townOptionsData?.townCodeList
  );

  //independent wss and town for HO RBDM and MH3
  useEffect(() => {
    if (Title === "HO Collaborator" || Title === "SH7") {
      setIndePendentWssOptions(wssOptionsFromRedux);
    }

    if (Title === "MH3" || Title === "RBDM Collaborator") {
      setIndependentWssOptionsForRbDmAndMH3(townList);
    }
  }, []);

  // wss and town api call for SH7 and Ho
  useEffect(() => {
    if (!_.isEmpty(selectedTerritory?.value)) {
      setPicklistLoader(true);
      (async () => {
        const index = selectedTerritory?.index;
        const wssData = selectedTerritory?.value?.split("~");
        let SH2_Code__c = wssData?.[0];
        let SH3_Code__c = wssData?.[1];
        sh2Name?.splice(index, 1, wssData?.[2]);
        setSh2Name((fs) => [...fs]);
        pidliteId?.splice(index, 1, wssData?.[3]);
        setPidliteId((fs) => [...fs]);
        if (
          Title !== "MH3" &&
          Title !== "RBDM Collaborator" &&
          Title !== "HO Collaborator" &&
          Title !== "SH7"
        ) {
          if (Title !== "SH3") {
            const response = await addNewTourWssApi(SH2_Code__c, SH3_Code__c);
            if (response?.success) {
              setWssIndex(index);
              setWssOption(response?.data?.WSSCode);
              setPicklistLoader(false);
            } else {
              setError(response?.msg);
            }
          }
        }
      })();
    }
  }, [selectedTerritory]);

  useEffect(() => {
    wssOptionList?.splice(wssIndex, 1, wssOption);
    setwssOptionList((fs) => [...fs]);
  }, [wssOption]);

  // to delete form
  const handleDelete = (e) => {
    e.preventDefault();
    const list = [...inputList];
    if (list.length > 1) {
      list?.splice(0 - 1);
    }
    setInputList(list);
  };

  const formatDate = (date) => {
    const parts = date.split('/');
    const formattedDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }).replace(',', '');
    return formattedDate;
  };

  //on Submit
  const onSubmitHandler = (data, type) => {
    inputList?.map((record, index) => {
      let date;
      if (_.isEmpty(record[`date${index}`])) {
        date = new Date()?.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      } else {
        date = new Date(record[`date${index}`])?.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      }
      const mtpCurrentDateData = upcomingTourPlansData.filter(item => formatDate(item.MTP_Date__c) === formatDate(date));
      if (mtpCurrentDateData.length >= 1){
        setIsInvalidDate(date);
        setIsDateValidModalOpen(true);
      }else{
        setAllData((fs) => [
        ...fs,
        {
          Datefields: date,
          Town: _.isEmpty(record[`town${index}`])
            ? _.isEmpty(inputList[index - 1]?.[`town${index - 1}`])
              ? inputList[0]?.town
              : inputList[index - 1]?.[`town${index - 1}`]
            : record[`town${index}`],
          Sh2_Name: sh2Name[index],
          Cluster: _.isEmpty(record[`cluster${index}`])
            ? _.isEmpty(inputList[index - 1]?.[`cluster${index - 1}`])
              ? inputList[0]?.cluster
              : inputList[index - 1]?.[`cluster${index - 1}`]
            : record[`cluster${index}`],
          Objectives: _.isEmpty(record[`primaryObjective${index}`])
            ? _.isEmpty(inputList[index - 1]?.[`primaryObjective${index - 1}`])
              ? inputList[0]?.primaryObjective0
              : inputList[index - 1]?.[`primaryObjective${index - 1}`]
            : record[`primaryObjective${index}`],
          wssCode: _.isEmpty(record[`wss${index}`])
            ? _.isEmpty(inputList[index - 1]?.[`wss${index - 1}`])
              ? inputList[0]?.wss
              : inputList[index - 1]?.[`wss${index - 1}`]
            : record[`wss${index}`],
          RecordType: type,
          Pidilite_Hierarchy_Id: pidliteId[index],
          status: "Not Visited",
          otherObjective:  otherPrimaryObj
        },
        ]);
      }
    });
  };

  //post api call
  useEffect(() => {
    setShowUpcomingTour(false);
    if (allData?.length == inputList?.length) {
      (async () => {
        setLoading(true);
        const response = await addNewTourPlanApi(allData);
        setLoading(false);
        if (response?.success) {
          allData?.map((data) => {
            if (data?.RecordType === "Monthly Tour Plan") {
              setIsSubmitModalOpen(true);
              setShowUpcomingTour(true);
            } else {
              setIsDraftModalOpen(true);
              setShowUpcomingTour(true);
            }
          });
          setInputList([
            {
              id: 0,
              date: "",
              recordType: "Monthly Tour Plan",
            },
          ]);
          setPidliteId([]);
          setAllData([]);
          setSh2Name([]);
          setWssSelectedName([]);
          setTownSelectedName([]);
          setTerritoryAutoCompletePlaceHolder(["Select Territory"]);

          setCluster([]);
          setRmdmTown([]);
          setTerritory([]);
          setUserTownArr([]);
          setUserWssArr([]);
          setUserPrimaryObjArr([]);
          if (
            Title === "MH3" ||
            Title === "RBDM Collaborator" ||
            Title === "HO Collaborator" || 
            Title === "SH7"
          ) {
            const town = [];
            townOptionsFromRedux.forEach((element) => {
              if (!town.includes(element.Town__c)) {
                town.push(element.Town__c);
              }
            });
            setTownSelectedName(town);
            setWssOption([...wssOptionsFromRedux]);
          } else {
            setWssOption([]);
            setwssOptionList([]);
            setWssRmdmOption([]);
          }
          reset();
        } else {
          console.log("error");
        }
      })();
    }
  }, [allData]);

  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    if (Title !== "SH3" && Title !== "SH5" && Title !== "SH6") {
      if (
        Object.keys(selectedTerritory)?.length !== 0 &&
        selectedTerritory !== "" &&
        wssOption?.length === 0 &&
        !isSubmitModalOpen &&
        !isDraftModalOpen
      ) {
        setFailesOpen(true);
      }
    }
  }, [wssOption]);

  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailesOpen(false);
  };

  // Function to get dealer city list for MH3, RBDM, Ho Collabrator
  const HoTownData = (CustomerCodes) =>{
    (async () => {
      const res = await getTownApi(CustomerCodes);
      if (res?.success) {
        if (res?.data?.length === 0 || res?.data?.records?.length === 0) {
          setIsTownData("false")
          setHoTownOptions([]);
        } else {
          setHoTownOptions(res?.data);
          setIsTownData("true")
        }
      }else{
        setIsTownData("false")
        setHoTownOptions([]);
      }
    })();
  }

  // Function to get customer code list using selected cluster code for MH3, RBDM
  useEffect(() => {
    if (
      cluster?.length &&
      (Title === "MH3" || Title === "RBDM Collaborator") &&
      !_.isEmpty(cluster?.[cluster?.length - 1]?.CLUSTER_CODE__c)
    ) {
      (async () => {
        const response = await getRmdmWssApi([
          cluster?.[cluster?.length - 1]?.CLUSTER_CODE__c,
        ]);
        if (response?.success) {
          // HoTownData Function to get dealer city list by providing the Customer Codes for MH3, RBDM
          
          HoTownData(response?.data);
          if (_.isEmpty(wssOption[index])) {
            setWssOption((ls) => [...ls, response?.data]);
          } else {
            wssOption[index] = response?.data;
            setWssOption([...wssOption]);
          }
        }
      })();
    }

    // -------------------get Dealer City-------------------- //

    if (cluster?.length && (Title === "MH3" || Title === "RBDM Collaborator" || Title === "HO Collaborator" || Title === "SH7") &&
      !_.isEmpty(cluster?.[cluster?.length - 1]?.SH3_Code__c)){
        (async () => {
          const response = await getCustomerCodeForTownApi([
            cluster?.[cluster?.length - 1]?.SH3_Code__c,
          ]);
          if (response?.success) {
            HoTownData(response?.data)
          }
        })();
      }
  }, [cluster]);

  // to get territory picklist when user is HO Collaborator
  useEffect(() => {
    if (
      cluster?.length &&
      (Title === "SH5" || Title === "SH6" || Title === "HO Collaborator" || Title === "SH7" ) &&
      !_.isEmpty(cluster?.[cluster?.length - 1]?.SH3_Code__c)
    ) {
      (async () => {
        const response = await getZSMTerritoryApi([
          cluster?.[cluster?.length - 1]?.SH3_Code__c,
        ]);
        if (Title !== "HO Collaborator" && Title !== "SH7") {
          const res = await getZSMWssNameApi(
            cluster?.[cluster?.length - 1]?.SH3_Code__c
          );
          if (res?.success) {
            setZsmWss(res?.data);
          } else {
            setZsmWss([]);
          }
        }
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList([]);
        }
      })();
    }
  }, [cluster]);

  useEffect(() => {
    if (userPrimaryObjArr.length > 0 && userPrimaryObjArr.some(item => item == 'Other')) {
      setShowTextField(true);
    } else {
      setShowTextField(false);
    }
  }, [userPrimaryObjArr])

  console.log("first", inputList);

  return (
    <>
      <CommonSnackbar
        open={failedOpen}
        autoHideDuration={7000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"error"}
        message={
          "Currently no wss related to this territory,please select another territory"
        }
      />
      <div className="newTourPlan__heading">
        <h6 className="newTourPlan__name">New Tour Plan</h6>
        <div>
          <Loader loading={loading} />
          <Form
            onSubmit={handleSubmit(
              (data) => onSubmitHandler(data, "Monthly Tour Plan"),
              onError
            )}
            style={{ width: "100%" }}
          >
            {inputList?.map((input, index) => {
              const todayDate = new Date();
              return (
                <div className="row newTourPlan_formData">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                    <label>
                      Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <input
                      type="date"
                      className="newTourPlan__input"
                      name={`date${index}`}
                      value={
                        _.isEmpty(input[`date${index}`])
                          ? todayDate?.toISOString()?.split("T")?.[0]
                          : input[`date${index}`]
                      }
                      // {...register(`date${index}`, {
                      //   validate: (value, formValues) =>
                      //     value >= todayDate?.toISOString()?.split("T")?.[0],
                      // })}
                      onChange={(e) => {
                        // errors[`date${index}`] = "";
                        onChangeHandler(e, index);
                      }}
                    />
                    {/* {errors[`date${index}`] && (
                      <p>Please select the future date</p>
                    )} */}
                  </div>

                  {Title !== "MH3" && Title !== "RBDM Collaborator" ? (
                    <>
                      {(Title === "HO Collaborator" ||
                        Title === "SH5" ||
                        Title === "SH6" ||
                        Title === "SH7") && (
                        <>
                          <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                            <label>
                              Cluster<span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Controller
                              name={`cluster${index}`}
                              rules={{
                                required: "Cluster is required",
                              }}
                              control={control}
                              defaultValue={
                                !_.isEmpty(cluster[index]?.SH3_Code__c) &&
                                cluster[index]
                              }
                              render={({ field, fieldState: { error } }) => {
                                let { onChange, value } = field;
                                return (
                                  <>
                                    <Autocomplete
                                      value={cluster[index]}
                                      ListboxProps={{
                                        sx: {
                                          fontSize: 12,
                                          color: "#2cc26a",
                                          width: "100%",
                                        },
                                      }}
                                      disableClearable={true}
                                      className="newTourPlan__inputs"
                                      options={
                                        !clusterCodeOption ||
                                        clusterCodeOption?.length === 0
                                          ? [
                                              {
                                                SH3_Name__c: "Loading...",
                                                SH3_Code__c: ""
                                              }
                                            ]
                                          : getUniqueListBy(
                                              clusterCodeOption,
                                              "SH3_Name__c"
                                            )
                                      }
                                      getOptionLabel={(option) =>
                                        `${option?.SH3_Name__c}${
                                          option?.SH3_Code__c &&
                                          `:${option?.SH3_Code__c}`
                                        }`
                                      }
                                      onChange={(e, newValue) => {
                                        if (_.isEmpty(cluster[index])) {
                                          setCluster((ls) => [...ls, newValue]);
                                        } else {
                                          cluster[index] = newValue;
                                          setCluster([...cluster]);
                                        }

                                        onChange(
                                          {
                                            target: {
                                              name: `cluster${index}`,
                                              value: `${newValue?.SH3_Name__c}${
                                                newValue?.SH3_Code__c &&
                                                `:${newValue?.SH3_Code__c}`
                                              }`,
                                            },
                                          },
                                          index
                                        );

                                        onChangeHandler(
                                          {
                                            target: {
                                              name: `cluster${index}`,
                                              value: `${newValue?.SH3_Name__c}${
                                                newValue?.SH3_Code__c &&
                                                `:${newValue?.SH3_Code__c}`
                                              }`,
                                            },
                                          },
                                          index
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder={"Select Cluster"}
                                        />
                                      )}
                                    />
                                  </>
                                );
                              }}
                            />
                            {!input[`cluster${index}`] &&
                              errors[`cluster${index}`] && (
                                <p>Please select the cluster</p>
                              )}{" "}
                          </div>

                          {/* To show territory for HO user */}
                          {(Title === "HO Collaborator" || Title === "SH7") && (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                              <label>Territory</label>
                              <br />

                              <Autocomplete
                                key={territoryAutoCompletePlaceHolder}
                                id="grouped-demo"
                                ListboxProps={{
                                  sx: {
                                    fontSize: 12,
                                    color: "#2cc26a",
                                    width: "100%",
                                  },
                                }}
                                className={
                                  !input[`territory${index}`] &&
                                  errors[`territory${index}`]
                                    ? "newTourPlan__inputError"
                                    : "newTourPlan__inputs"
                                }
                                options={
                                  territoryPickList?.length === 0
                                    ? [
                                        {
                                          SH2_Name__c: "Loading...",
                                          SH2_Code__c: "",
                                        },
                                      ]
                                    : getUniqueListBy(
                                        territoryPickList,
                                        "SH2_Code__c"
                                      )
                                }
                                getOptionLabel={(option) =>
                                  `${option?.SH2_Name__c}${
                                    option?.SH2_Code__c
                                      ? `:${option?.SH2_Code__c}`
                                      : ""
                                  }`
                                }
                                onChange={(e, newValue) => {
                                  handleTerritoryPlaceHandler(
                                    `${newValue.SH2_Name__c}:${newValue.SH2_Code__c}`,
                                    index
                                  );
                                  let name = `territory${index}`;
                                  onChangeHandler(
                                    {
                                      target: {
                                        name,
                                        value: `${newValue.SH2_Code__c}~${newValue.SH3_Code__c}~${newValue.SH2_Name__c}~${newValue.Id}`,
                                      },
                                    },
                                    index
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={
                                      territoryAutoCompletePlaceHolder[index]
                                    }
                                    size="small"
                                  />
                                )}
                              />
                              {/* {!input[`territory${index}`] &&
                              errors[`territory${index}`] && (
                                <p>Please select the territory</p>
                              )} */}
                            </div>
                          )}
                        </>
                      )}

                      {/* To show territory for BM NSM(SH7) */}
                      {Title !== "HO Collaborator" &&
                        Title !== "SH5" &&
                        Title !== "SH6" && 
                        Title !== "SH7" &&(
                          <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                            <label>
                              Territory<span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Controller
                              name={`territory${index}`}
                              rules={{
                                required: "Territory is required",
                              }}
                              control={control}
                              defaultValue={
                                !_.isEmpty(territory[index]?.SH2_Code__c) &&
                                territory[index]
                              }
                              render={({ field, fieldState: { error } }) => {
                                let { onChange, value } = field;
                                return (
                                  <Autocomplete
                                    value={territory[index]}
                                    // key={territoryAutoCompletePlaceHolder}
                                    id="grouped-demo"
                                    ListboxProps={{
                                      sx: {
                                        fontSize: 12,
                                        color: "#2cc26a",
                                        width: "100%",
                                      },
                                    }}
                                    disableClearable={true}
                                    className="newTourPlan__inputs"
                                    // className={
                                    //   !input[`territory${index}`] &&
                                    //   errors[`territory${index}`]
                                    //     ? "newTourPlan__inputError"
                                    //     : "newTourPlan__inputs"
                                    // }
                                    options={
                                      territoryOption?.length === 0
                                        ? [
                                            {
                                              SH2_Name__c: "Loading...",
                                              SH2_Code__c: "",
                                            },
                                          ]
                                        : getUniqueListBy(
                                            territoryOption,
                                            "SH2_Code__c"
                                          )
                                    }
                                    getOptionLabel={(option) =>
                                      `${option?.SH2_Name__c}${
                                        option?.SH2_Code__c
                                          ? `:${option?.SH2_Code__c}`
                                          : ""
                                      }`
                                    }
                                    onChange={(e, newValue) => {
                                      // handleTerritoryPlaceHandler(
                                      //   `${newValue.SH2_Name__c}:${newValue.SH2_Code__c}`,
                                      //   index
                                      // );

                                      if (_.isEmpty(territory[index])) {
                                        setTerritory((ls) => [...ls, newValue]);
                                      } else {
                                        territory[index] = newValue;
                                        setTerritory([...territory]);
                                      }

                                      onChange(
                                        {
                                          target: {
                                            name: `territory${index}`,
                                            value: `${newValue.SH2_Code__c}~${newValue.SH3_Code__c}~${newValue.SH2_Name__c}~${newValue.Id}`,
                                          },
                                        },
                                        index
                                      );

                                      onChangeHandler(
                                        {
                                          target: {
                                            name: `territory${index}`,
                                            value: `${newValue.SH2_Code__c}~${newValue.SH3_Code__c}~${newValue.SH2_Name__c}~${newValue.Id}`,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"Select Territory"}
                                        // size="small"
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                            {!input[`territory${index}`] &&
                              errors[`territory${index}`] && (
                                <p>Please select the territory</p>
                              )}
                          </div>
                        )}

                      {/* To show WSS for NSM and HO */}
                      {Title !== "SH5" &&
                        Title !== "SH6" &&
                        Title !== "SH3" &&
                        Title !== "SH7" &&
                        Title !== "HO Collaborator" && (
                          <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                            <label>
                              WSS
                            {(Title !== "HO Collaborator" || Title !== "SH7") && (
                                <span style={{ color: "red" }}>*</span>
                              )}
                            </label>
                            <br />
                            <select
                              className={
                                !input[`wss${index}`] && errors[`wss${index}`]
                                  ? "newTourPlan__inputError"
                                  : "newTourPlan__input"
                              }
                              label=""
                              name={`wss${index}`}
                              {...register(`wss${index}`, {
                                required:
                                  (Title !== "HO Collaborator" || Title !== "SH7") ? true : false,
                              })}
                              onChange={(e) => onChangeHandler(e, index)}
                            >
                              {_.isEmpty(input[input.wssKey]) ? (
                                <option
                                  value=""
                                  selected={
                                    _.isEmpty(input[input.wssKey])
                                      ? true
                                      : false
                                  }
                                  className="newTourPlan__defaultOption"
                                >
                                  select wss
                                </option>
                              ) : (
                                <option value={input[input.wssKey]}>
                                  {input[input.wssKey]}
                                </option>
                              )}

                              {!picklistLoader &&
                                // territoryName === `territory${index}` &&
                                wssOptionList?.[index]?.map((item) => {
                                  return (
                                    <option
                                      value={`${item?.CustomerName__c}:${item?.Customer_Code__c}:(${item?.CustomerGroup__c}),${item?.Town__c}`}
                                    >
                                      {item?.CustomerName__c}:
                                      {item?.Customer_Code__c} (
                                      {item?.CustomerGroup__c})
                                    </option>
                                  );
                                })}
                            </select>
                            {!input[`wss${index}`] && errors[`wss${index}`] && (
                              <p>Please select the wss</p>
                            )}
                          </div>
                        )}
                      {(Title === "HO Collaborator" || Title === "SH7") &&  (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label>
                            WSS test
                          </label>
                          <br />
                          <Controller
                            name={`wss${index}`}
                            // rules={{
                            //   required: "wss is required",
                            // }}
                            control={control}
                            defaultValue={
                              !_.isEmpty(
                                wssRmdmOption[index]?.CustomerName__c
                              ) && wssRmdmOption[index]
                            }
                            render={({ field }) => {
                              let { onChange, value } = field;
                              return (
                                <>
                                  <Autocomplete
                                    multiple
                                    value={userWssArr}
                                    className="newTourPlan__inputs_multiple"
                                    id="grouped-demo"
                                    ListboxProps={{
                                      sx: {
                                        fontSize: 12,
                                        color: "#2cc26a",
                                        width: "100%",
                                      },
                                    }}
                                    disableClearable={true}
                                    options={
                                      !independentWssOptions?.length
                                        ? [
                                            {
                                              CustomerName__c: "Loading...",
                                              Customer_Code__c: "",
                                            },
                                          ]
                                        : independentWssOptions
                                    }
                                    getOptionLabel={(option) =>
                                      `${option?.CustomerName__c}${
                                        option?.Customer_Code__c &&
                                        `:${option?.Customer_Code__c}`
                                      }`
                                    }
                                    onChange={(e, newValue) => {
                                      setUserWssArr(newValue);
                                      if (_.isEmpty(wssRmdmOption[index])) {
                                        setWssRmdmOption((ls) => [
                                          ...ls,
                                          newValue,
                                        ]);
                                      } else {
                                        wssRmdmOption[index] = newValue;
                                        setWssRmdmOption([...wssRmdmOption]);
                                      }

                                      let wssValue = "";

                                      newValue.forEach((value) => {
                                        wssValue =
                                          wssValue +
                                          `${value?.CustomerName__c}:${value?.Customer_Code__c}:(${value?.CustomerGroup__c}),${value?.Town__c}` +
                                          ",";
                                      });

                                      onChange(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: wssValue,
                                          },
                                        },
                                        index
                                      );
                                      onChangeHandler(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: wssValue,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={
                                          userWssArr.length > 0
                                            ? ""
                                            : "Select Wss"
                                        }
                                      />
                                    )}
                                  />
                                </>
                              );
                            }}
                          />

                          {!input[`wss${index}`] && errors[`wss${index}`] && (
                            <p>Please select the wss</p>
                          )}
                        </div>
                      )}

                      {/* show territory and WSS for ZSM */}
                      {(Title === "SH5" || Title === "SH6") && (
                        <>
                          <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                            <label>
                              Territory<span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Controller
                              name={`territory${index}`}
                              rules={{
                                required: "Territory is required",
                              }}
                              control={control}
                              defaultValue={
                                !_.isEmpty(territory[index]?.SH3_Code__c) &&
                                territory[index]
                              }
                              render={({ field, fieldState: { error } }) => {
                                let { onChange, value } = field;
                                return (
                                  <Autocomplete
                                    value={territory[index]}
                                    id="grouped-demo"
                                    ListboxProps={{
                                      sx: {
                                        fontSize: 12,
                                        color: "#2cc26a",
                                        width: "100%",
                                      },
                                    }}
                                    disableClearable={true}
                                    className="newTourPlan__inputs"
                                    options={
                                      !territoryPickList ||
                                      territoryPickList?.length === 0
                                        ? [
                                            {
                                              SH2_Name__c: "Loading...",
                                              SH2_Code__c: "",
                                            },
                                          ]
                                        : getUniqueListBy(
                                            territoryPickList,
                                            "SH2_Code__c"
                                          )
                                    }
                                    getOptionLabel={(option) =>
                                      `${option?.SH2_Name__c}${
                                        option?.SH2_Code__c
                                          ? `:${option?.SH2_Code__c}`
                                          : ""
                                      }`
                                    }
                                    onChange={(e, newValue) => {
                                      if (_.isEmpty(territory[index])) {
                                        setTerritory((ls) => [...ls, newValue]);
                                      } else {
                                        territory[index] = newValue;
                                        setTerritory([...territory]);
                                      }

                                      onChange(
                                        {
                                          target: {
                                            name: `territory${index}`,
                                            value: `${newValue.SH2_Code__c}~${newValue.SH3_Code__c}~${newValue.SH2_Name__c}~${newValue.Id}`,
                                          },
                                        },
                                        index
                                      );

                                      onChangeHandler(
                                        {
                                          target: {
                                            name: `territory${index}`,
                                            value: `${newValue.SH2_Code__c}~${newValue.SH3_Code__c}~${newValue.SH2_Name__c}~${newValue.Id}`,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"Select Territory"}
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                            {!input[`territory${index}`] &&
                              errors[`territory${index}`] && (
                                <p>Please select the territory</p>
                              )}
                          </div>

                          <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                            <label>
                              WSS<span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <Controller
                              name={`wss${index}`}
                              rules={{
                                required: "wss is required",
                              }}
                              control={control}
                              defaultValue={
                                !_.isEmpty(
                                  wssRmdmOption[index]?.CustomerName__c
                                ) && wssRmdmOption[index]
                              }
                              render={({ field, fieldState: { error } }) => {
                                let { onChange, value } = field;
                                return (
                                  <Autocomplete
                                    value={wssRmdmOption[index]}
                                    id="grouped-demo"
                                    ListboxProps={{
                                      sx: {
                                        fontSize: 12,
                                        color: "#2cc26a",
                                        width: "100%",
                                      },
                                    }}
                                    disableClearable={true}
                                    className="newTourPlan__inputs"
                                    options={
                                      !zmsWss || zmsWss?.length === 0
                                        ? [
                                            {
                                              CustomerName__c: "Loading...",
                                              Customer_Code__c: "",
                                            },
                                          ]
                                        : getUniqueListBy(
                                            zmsWss,
                                            "Customer_Code__c"
                                          )
                                    }
                                    getOptionLabel={(option) =>
                                      `${option?.CustomerName__c}${
                                        option?.Customer_Code__c &&
                                        `:${option?.Customer_Code__c}`
                                      }`
                                    }
                                    onChange={(e, newValue) => {
                                      if (_.isEmpty(wssRmdmOption[index])) {
                                        setWssRmdmOption((ls) => [
                                          ...ls,
                                          newValue,
                                        ]);
                                      } else {
                                        wssRmdmOption[index] = newValue;
                                        setWssRmdmOption([...wssRmdmOption]);
                                      }

                                      onChange(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                                          },
                                        },
                                        index
                                      );

                                      onChangeHandler(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"Select WSS"}
                                      />
                                    )}
                                  />
                                );
                              }}
                            />
                            {!input[`wss${index}`] && errors[`wss${index}`] && (
                              <p>Please select the wss</p>
                            )}
                          </div>
                        </>
                      )}

                      {/* To show independent wss for BM user */}
                      {Title === "SH3" && (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label>
                            WSS<span style={{ color: "red" }}>*</span>
                          </label>
                          <br />

                          <Controller
                            name={`wss${index}`}
                            rules={{
                              required: "Wss is required",
                            }}
                            control={control}
                            defaultValue={
                              !_.isEmpty(
                                wssRmdmOption[index]?.CustomerName__c
                              ) && wssRmdmOption[index]
                            }
                            render={({ field, fieldState: { error } }) => {
                              let { onChange, value } = field;
                              return (
                                <Autocomplete
                                  value={wssRmdmOption[index]}
                                  id="grouped-demo"
                                  ListboxProps={{
                                    sx: {
                                      fontSize: 12,
                                      color: "#2cc26a",
                                      width: "100%",
                                    },
                                  }}
                                  disableClearable={true}
                                  className="newTourPlan__inputs"
                                  options={
                                    !bmWssList?.length
                                      ? [
                                          {
                                            CustomerName__c: "Loading...",
                                            Customer_Code__c: "",
                                          },
                                        ]
                                      : bmWssList
                                  }
                                  getOptionLabel={(option) =>
                                    `${option?.CustomerName__c}${
                                      option?.Customer_Code__c &&
                                      `:${option?.Customer_Code__c}`
                                    }`
                                  }
                                  onChange={(e, newValue) => {
                                    if (_.isEmpty(wssRmdmOption[index])) {
                                      setWssRmdmOption((ls) => [
                                        ...ls,
                                        newValue,
                                      ]);
                                    } else {
                                      wssRmdmOption[index] = newValue;
                                      setWssRmdmOption([...wssRmdmOption]);
                                    }

                                    onChange(
                                      {
                                        target: {
                                          name: `wss${index}`,
                                          value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                                        },
                                      },
                                      index
                                    );

                                    onChangeHandler(
                                      {
                                        target: {
                                          name: `wss${index}`,
                                          value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                                        },
                                      },
                                      index
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Select Wss"}
                                    />
                                  )}
                                />
                              );
                            }}
                          />

                          {!input[`wss${index}`] && errors[`wss${index}`] && (
                            <p>Please select the wsss</p>
                          )}
                        </div>
                      )}
                      {Title !== "HO Collaborator" && Title !== "SH7" && (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label style={{ whiteSpace: "nowrap" }}>
                            Town
                            {(Title !== "HO Collaborator" || Title !== "SH7") && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <br />

                          <Controller
                            name={`town${index}`}
                            rules={{
                              required: "Wss is required",
                            }}
                            control={control}
                            defaultValue={
                              !_.isEmpty(
                                wssRmdmOption[index]?.CustomerName__c
                              ) && wssRmdmOption[index]
                            }
                            render={({ field, fieldState: { error } }) => {
                              let { onChange, value } = field;
                              return (
                                <Autocomplete
                                  // value={wssRmdmOption[index]}
                                  id="grouped-demo"
                                  ListboxProps={{
                                    sx: {
                                      fontSize: 12,
                                      color: "#2cc26a",
                                      width: "100%",
                                    },
                                  }}
                                  disableClearable={true}
                                  className="newTourPlan__inputs"
                                  options={
                                    isTownData === "Loading"
                                      ? [
                                        {
                                          DealerCity__c: "Loading..."
                                        },
                                      ]
                                      : isTownData === "false" ? [
                                        {
                                          DealerCity__c: "No Data"
                                        },
                                      ] : bmTownOptions
                                  }
                                  getOptionLabel={(option) =>
                                    `${option?.DealerCity__c}`
                                  }
                                  onChange={(e, newValue) => {
                                    onChange(
                                      {
                                        target: {
                                          name: `town${index}`,
                                          value: `${newValue?.DealerCity__c}`,
                                        },
                                      },
                                      index
                                    );

                                    onChangeHandler(
                                      {
                                        target: {
                                          name: `town${index}`,
                                          value: `${newValue?.DealerCity__c}`,
                                        },
                                      },
                                      index
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Select Town"}
                                    />
                                  )}
                                />
                              );
                            }}
                          />

                          {!input[`town${index}`] && errors[`town${index}`] && (
                            <p>Please select the town</p>
                          )}
                        </div>
                      )}
                      {(Title === "HO Collaborator" || Title === "SH7") &&  (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label>Town</label>
                          <br />
                          <Controller
                            name={`rmdmTown${index}`}
                            // rules={{
                            //   required: "Town is required",
                            // }}
                            control={control}
                            defaultValue={
                              !_.isEmpty(rmdmTown[index]?.Town__c) &&
                              rmdmTown[index]
                            }
                            render={({ field }) => {
                              let { onChange, value } = field;
                              return (
                                <>
                                  <Autocomplete
                                    multiple
                                    value={userTownArr}
                                    className="newTourPlan__inputs_multiple"
                                    id="grouped-demo"
                                    ListboxProps={{
                                      sx: {
                                        fontSize: 12,
                                        color: "#2cc26a",
                                        width: "100%",
                                      },
                                    }}
                                    disableClearable={true}
                                    // options={hoTownOptions}
                                    options={
                                      isTownData === "Loading"
                                        ? [
                                          {
                                            DealerCity__c: "Loading..."
                                          },
                                        ]
                                        : isTownData === "false" ? [
                                          {
                                            DealerCity__c: "No Data"
                                          },
                                        ] : hoTownOptions
                                    }
                                    getOptionLabel={(option) => option?.DealerCity__c}
                                    onChange={(e, newValue) => {
                                      setUserTownArr(newValue);
                                      setIsTownData("true");
                                      if (_.isEmpty(rmdmTown[index])) {
                                        setRmdmTown((ls) => [...ls, newValue]);
                                      } else {
                                        rmdmTown[index] = newValue;
                                        setRmdmTown([...rmdmTown]);
                                      }

                                      let userTown = "";
                                      newValue.forEach((value) => {
                                        userTown =
                                          userTown + value?.DealerCity__c + ",";
                                      });

                                      onChange(
                                        {
                                          target: {
                                            name: `town${index}`,
                                            value: userTown,
                                          },
                                        },
                                        index
                                      );

                                      onChangeHandler(
                                        {
                                          target: {
                                            name: `town${index}`,
                                            value: userTown,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={
                                          userTownArr.length > 0
                                            ? ""
                                            : "Select Town"
                                        }
                                      />
                                    )}
                                  />
                                </>
                              );
                            }}
                          />

                          {/* {!input[`rmdmTown${index}`] &&
                            errors[`rmdmTown${index}`] && (
                              <p>Please select the town</p>
                            )} */}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                        <label>
                          Cluster<span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <Controller
                          name={`cluster${index}`}
                          rules={{
                            required: "Cluster is required",
                          }}
                          control={control}
                          defaultValue={
                            !_.isEmpty(cluster[index]?.CLUSTER_CODE__c) &&
                            cluster[index]
                          }
                          render={({ field, fieldState: { error } }) => {
                            let { onChange, value } = field;
                            return (
                              <>
                                <Autocomplete
                                  value={cluster[index]}
                                  ListboxProps={{
                                    sx: {
                                      fontSize: 12,
                                      color: "#2cc26a",
                                      width: "100%",
                                    },
                                  }}
                                  disableClearable={true}
                                  className="newTourPlan__inputs"
                                  options={
                                    !clusterCodeOption ||
                                    clusterCodeOption?.length === 0
                                      ? [
                                          {
                                            SH3_Name__c: "Loading...",
                                            SH3_Code__c: "",
                                            Cluster_Description__c:
                                              "Loading...",
                                            CLUSTER_CODE__c: "",
                                          },
                                        ]
                                      : getUniqueListBy(
                                          clusterCodeOption,
                                          Title === "MH3" ||
                                            Title === "RBDM Collaborator"
                                            ? "CLUSTER_CODE__c"
                                            : "SH3_Name__c"
                                        )
                                  }
                                  getOptionLabel={(option) =>
                                    Title !== "MH3" &&
                                    Title !== "RBDM Collaborator"
                                      ? `${option?.SH3_Name__c}${
                                          option?.SH3_Code__c &&
                                          `:${option?.SH3_Code__c}`
                                        }`
                                      : `${option?.Cluster_Description__c}${
                                          option?.CLUSTER_CODE__c &&
                                          `:${option?.CLUSTER_CODE__c}`
                                        }`
                                  }
                                  onChange={(e, newValue) => {
                                    if (_.isEmpty(cluster[index])) {
                                      setCluster((ls) => [...ls, newValue]);
                                    } else {
                                      cluster[index] = newValue;
                                      setCluster([...cluster]);
                                    }

                                    onChange(
                                      {
                                        target: {
                                          name: `cluster${index}`,
                                          value:
                                            Title === "MH3" ||
                                            Title === "RBDM Collaborator"
                                              ? `${
                                                  newValue?.Cluster_Description__c
                                                }${
                                                  newValue?.CLUSTER_CODE__c &&
                                                  `:${newValue?.CLUSTER_CODE__c}`
                                                }`
                                              : newValue?.SH3_Code__c,
                                        },
                                      },
                                      index
                                    );

                                    onChangeHandler(
                                      {
                                        target: {
                                          name: `cluster${index}`,
                                          value:
                                            Title === "MH3" ||
                                            Title === "RBDM Collaborator"
                                              ? `${
                                                  newValue?.Cluster_Description__c
                                                }${
                                                  newValue?.CLUSTER_CODE__c &&
                                                  `:${newValue?.CLUSTER_CODE__c}`
                                                }`
                                              : newValue?.SH3_Code__c,
                                        },
                                      },
                                      index
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Select Cluster"}
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                        {!input[`cluster${index}`] &&
                          errors[`cluster${index}`] && (
                            <p>Please select the cluster</p>
                          )}{" "}
                      </div>

                      {Title !== "MH3" && Title !== "RBDM Collaborator" && (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label>
                            WSS<span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <Controller
                            name={`wss${index}`}
                            rules={{
                              required: "wss is required",
                            }}
                            control={control}
                            defaultValue={
                              !_.isEmpty(
                                wssRmdmOption[index]?.CustomerName__c
                              ) && wssRmdmOption[index]
                            }
                            render={({ field, fieldState: { error } }) => {
                              let { onChange, value } = field;
                              return (
                                <>
                                  <Autocomplete
                                    value={wssRmdmOption[index]}
                                    id="grouped-demo"
                                    ListboxProps={{
                                      sx: {
                                        fontSize: 12,
                                        color: "#2cc26a",
                                        width: "100%",
                                      },
                                    }}
                                    disableClearable={true}
                                    className="newTourPlan__inputs"
                                    options={
                                      !wssOption[index] && !wssOption?.length
                                        ? [
                                            {
                                              CustomerName__c: "Loading...",
                                              Customer_Code__c: "",
                                            },
                                          ]
                                        : wssOption[index]
                                    }
                                    getOptionLabel={(option) =>
                                      `${option?.CustomerName__c}${
                                        option?.Customer_Code__c &&
                                        `:${option?.Customer_Code__c}`
                                      }`
                                    }
                                    onChange={(e, newValue) => {
                                      if (_.isEmpty(wssRmdmOption[index])) {
                                        setWssRmdmOption((ls) => [
                                          ...ls,
                                          newValue,
                                        ]);
                                      } else {
                                        wssRmdmOption[index] = newValue;
                                        setWssRmdmOption([...wssRmdmOption]);
                                      }

                                      onChange(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                                          },
                                        },
                                        index
                                      );
                                      onChangeHandler(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: `${newValue?.CustomerName__c}:${newValue?.Customer_Code__c}:(${newValue?.CustomerGroup__c}),${newValue?.Town__c}`,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={"Select Wss"}
                                      />
                                    )}
                                  />
                                </>
                              );
                            }}
                          />

                          {!input[`wss${index}`] && errors[`wss${index}`] && (
                            <p>Please select the wss</p>
                          )}
                        </div>
                      )}
                      {(Title === "MH3" || Title === "RBDM Collaborator") && (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label>
                            WSS<span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <Controller
                            name={`wss${index}`}
                            rules={{
                              required: "wss is required",
                            }}
                            control={control}
                            defaultValue={
                              !_.isEmpty(
                                wssRmdmOption[index]?.CustomerName__c
                              ) && wssRmdmOption[index]
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={
                                  territoryAutoCompletePlaceHolder[index]
                                }
                                size="small"
                              />
                            )}
                            render={({ field, fieldState: { error } }) => {
                              let { onChange, value } = field;
                              return (
                                <>
                                  <Autocomplete
                                    multiple
                                    value={userWssArr}
                                    id="grouped-demo"
                                    ListboxProps={{
                                      sx: {
                                        fontSize: 12,
                                        color: "#2cc26a",
                                        width: "100%",
                                      },
                                    }}
                                    disableClearable={true}
                                    className="newTourPlan__inputs_multiple"
                                    options={
                                      !independentWssOptionsForRbDmAndMH3?.length
                                        ? [
                                            {
                                              CustomerName__c: "Loading...",
                                              Customer_Code__c: "",
                                            },
                                          ]
                                        : independentWssOptionsForRbDmAndMH3
                                    }
                                    getOptionLabel={(option) =>
                                      `${option?.CustomerName__c}${
                                        option?.Customer_Code__c &&
                                        `:${option?.Customer_Code__c}`
                                      }`
                                    }
                                    onChange={(e, newValue) => {
                                      setUserWssArr(newValue);
                                      if (_.isEmpty(wssRmdmOption[index])) {
                                        setWssRmdmOption((ls) => [
                                          ...ls,
                                          newValue,
                                        ]);
                                      } else {
                                        wssRmdmOption[index] = newValue;
                                        setWssRmdmOption([...wssRmdmOption]);
                                      }

                                      let wssValue = "";

                                      newValue.forEach((value) => {
                                        wssValue =
                                          wssValue +
                                          `${value?.CustomerName__c}:${value?.Customer_Code__c}:(${value?.CustomerGroup__c}),${value?.Town__c}` +
                                          ",";
                                      });

                                      onChange(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: wssValue,
                                          },
                                        },
                                        index
                                      );
                                      onChangeHandler(
                                        {
                                          target: {
                                            name: `wss${index}`,
                                            value: wssValue,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder={
                                          userWssArr.length > 0
                                            ? ""
                                            : "Select Wss"
                                        }
                                      />
                                    )}
                                  />
                                </>
                              );
                            }}
                          />

                          {!input[`wss${index}`] && errors[`wss${index}`] && (
                            <p>Please select the wss</p>
                          )}
                        </div>
                      )}

                      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                        <label>
                          Town<span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <Controller
                          name={`rmdmTown${index}`}
                          rules={{
                            required: "Town is required",
                          }}
                          control={control}
                          defaultValue={
                            !_.isEmpty(rmdmTown[index]?.Town__c) &&
                            rmdmTown[index]
                          }
                          render={({ field, fieldState: { error } }) => {
                            let { onChange, value } = field;
                            return (
                              <>
                                <Autocomplete
                                  multiple
                                  value={userTownArr}
                                  className="newTourPlan__inputs_multiple"
                                  id="grouped-demo"
                                  ListboxProps={{
                                    sx: {
                                      fontSize: 12,
                                      color: "#2cc26a",
                                      width: "100%",
                                    },
                                  }}
                                  disableClearable={true}
                                  // options={getUniqueListBy(
                                  //   hoTownOptions,
                                  //   "DealerCity__c"
                                  // )}
                                  options={
                                    isTownData === "Loading"
                                      ? [
                                        {
                                          DealerCity__c: "Loading..."
                                        },
                                      ]
                                      : isTownData === "false" ? [
                                        {
                                          DealerCity__c: "No Data"
                                        },
                                      ] : hoTownOptions
                                  }
                                  getOptionLabel={(option) => option?.DealerCity__c}
                                  onChange={(e, newValue) => {
                                    setUserTownArr(newValue);
                                    if (_.isEmpty(rmdmTown[index])) {
                                      setRmdmTown((ls) => [...ls, newValue]);
                                    } else {
                                      rmdmTown[index] = newValue;
                                      setRmdmTown([...rmdmTown]);
                                    }

                                    let userTown = "";
                                    newValue.forEach((value) => {
                                      userTown =
                                        userTown + value?.DealerCity__c + ",";
                                    });

                                    onChange(
                                      {
                                        target: {
                                          name: `town${index}`,
                                          value: userTown,
                                        },
                                      },
                                      index
                                    );

                                    onChangeHandler(
                                      {
                                        target: {
                                          name: `town${index}`,
                                          value: userTown,
                                        },
                                      },
                                      index
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={
                                        userTownArr.length > 0
                                          ? ""
                                          : "Select Town"
                                      }
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />

                        {!input[`rmdmTown${index}`] &&
                          errors[`rmdmTown${index}`] && (
                            <p>Please select the town</p>
                          )}
                      </div>
                    </>
                  )}

                  {(Title === "HO Collaborator" || Title === "SH7") &&  (
                    <>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                        <label>
                          Primary Objective
                          {Title !== "HO Collaborator" && Title !== "SH7" && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </label>
                        <br />
                        <Controller
                          name={`primaryObjective${index}`}
                          // rules={{
                          //   required: "Objective is required",
                          // }}
                          control={control}
                          defaultValue={
                            !_.isEmpty(rmdmTown[index]?.Town__c) && rmdmTown[index]
                          }
                          render={({ field }) => {
                            let { onChange, value } = field;
                            return (
                              <>
                                <Autocomplete
                                  multiple
                                  value={userPrimaryObjArr}
                                  className="newTourPlan__inputs_multiple"
                                  id="grouped-demo"
                                  ListboxProps={{
                                    sx: {
                                      fontSize: 12,
                                      color: "#2cc26a",
                                      width: "100%",
                                    },
                                  }}
                                  disableClearable={true}
                                  options={primaryObjectivesOption}
                                  getOptionLabel={(option) => option}
                                  onChange={(e, newValue) => {
                                    setUserPrimaryObjArr(newValue);
                                    onChange(
                                      {
                                        target: {
                                          name: `primaryObjective${index}`,
                                          value: newValue.join(";"),
                                        },
                                      },
                                      index
                                    );

                                    onChangeHandler(
                                      {
                                        target: {
                                          name: `primaryObjective${index}`,
                                          value: newValue.join(";"),
                                        },
                                      },
                                      index
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={
                                        userPrimaryObjArr.length > 0
                                          ? ""
                                          : "Select Primary Objective"
                                      }
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                        {!input[`primaryObjective${index}`] &&
                      errors[`primaryObjective${index}`] && (
                        <p>Please select the primary Objective</p>
                      )}
                      </div>
                      {showTextField && (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label>
                            Other Primary Objective
                            {(Title !== "HO Collaborator" || Title !== "SH7") && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <input
                            type="text"
                            name={`OtherPrimaryObjective${index}`}
                            // value={otherPrimaryObj}
                            className="newTourPlan__inputs_Other"
                            placeholder="Enter Other Primary Objective"
                            onChange={(e) => {
                              setOtherPrimaryObj(e.target.value)
                            }}
                          // {...register(`OtherPrimaryObjective${index}`, {
                          //   required: true
                          // })}
                          />
                          {/* {!input[`OtherPrimaryObjective${index}`] && errors[`OtherPrimaryObjective${index}`] && (
                        <p>Please select the Other Primary Objective</p>
                      )} */}
                        </div>
                      )}
                    </>
                    )}

                  {Title !== "HO Collaborator" && Title !== "SH7" && (
                    <>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                        <label>
                          Primary Objective
                          {(Title !== "HO Collaborator" || Title !== "SH7") && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </label>
                        <br />
                        <Controller
                          name={`primaryObjective${index}`}
                          rules={{
                            required: "Objective is required",
                          }}
                          control={control}
                          defaultValue={
                            !_.isEmpty(rmdmTown[index]?.Town__c) && rmdmTown[index]
                          }
                          render={({ field, fieldState: { error } }) => {
                            let { onChange, value } = field;
                            return (
                              <>
                                <Autocomplete
                                  multiple
                                  value={userPrimaryObjArr}
                                  className="newTourPlan__inputs_multiple"
                                  id="grouped-demo"
                                  ListboxProps={{
                                    sx: {
                                      fontSize: 12,
                                      color: "#2cc26a",
                                      width: "100%",
                                    },
                                  }}
                                  disableClearable={true}
                                  options={primaryObjectivesOption}
                                  getOptionLabel={(option) => option}
                                  onChange={(e, newValue) => {
                                    setUserPrimaryObjArr(newValue);
                                    onChange(
                                      {
                                        target: {
                                          name: `primaryObjective${index}`,
                                          value: newValue.join(";"),
                                        },
                                      },
                                      index
                                    );

                                    onChangeHandler(
                                      {
                                        target: {
                                          name: `primaryObjective${index}`,
                                          value: newValue.join(";"),
                                        },
                                      },
                                      index
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={
                                        userPrimaryObjArr.length > 0
                                          ? ""
                                          : "Select Primary Objective"
                                      }
                                    />
                                  )}
                                />
                              </>
                            );
                          }}
                        />
                    {!input[`primaryObjective${index}`] &&
                      errors[`primaryObjective${index}`] && (
                        <p>Please select the primary Objective</p>
                      )}
                      </div>
                      {showTextField && (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                          <label>
                            Other Primary Objective
                            {(Title !== "HO Collaborator" || Title !== "SH7") && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <input
                            type="text"
                            name={`OtherPrimaryObjective${index}`}
                            // value={otherPrimaryObj}
                            className="newTourPlan__inputs_Other"
                            placeholder="Enter Other Primary Objective"
                            onChange={(e) => {
                              setOtherPrimaryObj(e.target.value)
                            }}
                          {...register(`OtherPrimaryObjective${index}`, {
                            required: true
                          })}
                          />
                          {!input[`OtherPrimaryObjective${index}`] && errors[`OtherPrimaryObjective${index}`] && (
                        <p>Please select the Other Primary Objective</p>
                      )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}

            <div className="newTourPlan__formButtons">
              <div>
                {inputList?.length < 10 && (
                  <button
                    type="submit"
                    onClick={addFormHandler}
                    className="newTourPlan__copy"
                  >
                    <FiCopy
                      style={{ marginRight: "5px", marginBottom: "3px" }}
                    />
                    <span style={{ fontSize: "13px" }}>Copy</span>
                  </button>
                )}
              </div>
              <div>
                {inputList?.length > 1 && (
                  <button
                    className="newTourPlan__delete"
                    onClick={handleDelete}
                  >
                    <RiDeleteBin5Line
                      style={{ marginRight: "5px", marginBottom: "2px" }}
                    />
                    <span style={{ fontSize: "13px" }}>Delete</span>
                  </button>
                )}
              </div>

              <button
                className="newTourPlan__save"
                onClick={handleSubmit(
                  (data) => onSubmitHandler(data, "Draft"),
                  onError
                )}
              >
                Save as Draft
              </button>
              <Button
                variant="secondary"
                className="newTourPlan__submit"
                type="submit"
              >
                <p className="newTourPlan__submitBtn">Submit</p>
              </Button>
            </div>
            {isDraftModalOpen && (
              <ModalPopup
                className="ModalPopup_Submitted"
                isModalOpen={isDraftModalOpen}
                setIsModalOpen={setIsDraftModalOpen}
                title="Saved as Draft"
                data="Your Tour plan is saved as draft successfully"
                backdrop={true}
                fade={false}
                icon={
                  <>
                    <div className="submittedIcons draftIcons">
                      <RiDraftFill className="draftSmall" />
                      <RiDraftFill className="draftLarge" />
                      <RiDraftFill className="draftSmall" />
                    </div>
                  </>
                }
              />
            )}
            {isSubmitModalOpen && (
              <ModalPopup
                className="ModalPopup_Submitted"
                isModalOpen={isSubmitModalOpen}
                setIsModalOpen={setIsSubmitModalOpen}
                title="Submitted Successfully"
                data="Your tour plan has been submitted successfully."
                backdrop={true}
                fade={false}
                icon={
                  <>
                    <div className="submittedIcons draftIcons">
                      <FaThumbsUp className="submittedSmall" />
                      <FaThumbsUp className="submittedLarge" />
                      <FaThumbsUp className="submittedSmall" />
                    </div>
                  </>
                }
              />
            )}
            {isDateValidModalOpen && (
              <ModalPopup
                className="ModalPopup_Submitted"
                isModalOpen={isDateValidModalOpen}
                setIsModalOpen={setIsDateValidModalOpen}
                title="Limit Exceed"
                data={`Tour already exists for ${isInvalidDate}`}
                backdrop={true}
                fade={false}
                icon={
                  <>
                    <div className="submittedIcons draftIcons">
                      <BsInfoCircle className="submittedSmall" style={{ color: '#77BFC7' }} />
                      <BsInfoCircle className="submittedLarge" style={{ color: '#77BFC7' }} />
                      <BsInfoCircle className="submittedSmall" style={{ color: '#77BFC7' }} />
                    </div>
                  </>
                }
              />
            )}
          </Form>
        </div>
      </div>
      <UpcomingTourPlans showUpcomingTour={showUpcomingTour} />
    </>
  );
};

export default connect(null, {
  addNewTourPlanApi,
  addNewTourWssApi,
  getZSMTerritoryApi,
  getRmdmWssApi,
  getZSMWssNameApi,
})(NewTour);
