import axios from "axios";
import { BASE_URL } from "../../variables";

export const getZsmDrmMeetingListApi = async (userEmail, Title) => {
    console.log("userEmail", userEmail);

    const options = {
        url: `${BASE_URL}/services/data/v56.0/query?q=SELECT Id, 
        SG__c, 
        Zone__c, 
        Cluster_Name__c, 
        TSI_Territory_Name__c, 
        Town_name__c, 
        Customer_Name__c, 
        User_Email__c, 
        isZSMCreated__c, 
        No_of_Dealers_invited__c, 
        Reason_for_exceeding_Budget__c, 
        Actual_out_of_pocket_expenses__c, 
        Budget_out_of_Pocket_expenses__c, 
        Primary_Reason_for_DRM__c, 
        Total_Cost__c, 
        Cost_Per_Head__c,
        Updated_Total_Cost__c,
        Updated_Cost_per_Head__c,
        NSM_Feedback__c,
        Tentative_date_of_the_meet__c,
        Actual_no_of_Dealers_Participated__c, 
        Actual_Date_of_the_Meet__c,     
        ZSM_Status__c, 
        NSM_Status__c
        FROM Dealer_Relationship_Meet__c
        WHERE User_Email__c= '${userEmail}'`,
        method: "get",
    };
    try {
        const response = await axios(options);
        console.log("res", response);
        if (response.status === 200) {
            return {
                success: true,
                data: response?.data,
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};
