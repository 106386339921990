import axios from "axios";
import { BASE_URL } from "../../variables";

export const updateZsmDrmMeetingApi = async (allData) => {
    const options = {
        url: `${BASE_URL}/services/apexrest/updateZsmDrmMeeting/`,
        method: "PUT",
        data: allData,
    };

    try {
        const response = await axios(options);
        if (
            response.status === 200 ||
            (response?.results?.length > 0 && response?.hasErrors === false)
        ) {
            return {
                success: true,
                data: response?.data,
            };
        } else {
            throw new Error("Something Went Wrong..!!");
        }
    } catch (err) {
        return {
            success: false,
            msg: "Something Went Wrong..!!",
        };
    }
};
