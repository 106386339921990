import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { getZsmDrmMeetingListApi } from '../services/DRM/getZsmDrmMeetingListApi';
import { Row, Col, Button } from "react-bootstrap";
import styles from "../Components/MonthlyTourPlan/UpcomingTourPlans.module.css";
import { HiOutlineArrowRight } from "react-icons/hi";
import { CiVideoOn } from "react-icons/ci";
import PageHeader from "../Components/Common/PageHeader";
import { useNavigate, Link } from "react-router-dom";

const DrmZsmMeetingList = () => {

    const navigate = useNavigate();
    const [zsmDrmMeetingList, setZsmDrmMeetingList] = useState([]);
    const userEmail = useSelector((state) => state?.userDetails?.details?.Email);

    useEffect(() => {
        console.log("userEmail", userEmail)
        if (userEmail) {
            (async () => {
                const response = await getZsmDrmMeetingListApi(userEmail);
                console.log("res", response)
                if (response?.success) {
                    setZsmDrmMeetingList(response?.data?.records);
                } else {
                    setZsmDrmMeetingList("");
                }
            })();
        }
    }, [userEmail]);

    const handleClick = (item) => {
        console.log("data", item);
        navigate(`/drm-meeting`, { state: item });
    };

    return (
        <>
            <PageHeader title="DRM Planned Meetings" path="/" />
            <div className="container">
                <div className="upcomingTourPlans">
                    <Col>
                        <Row style={{ alignItems: "center" }}></Row>
                        {zsmDrmMeetingList &&
                            zsmDrmMeetingList?.map((item, index) => (
                                <>
                                    <div className={`${styles.upcomingTourPlans__outerBox}`} onClick={() => handleClick(item)}>
                                        <div className={`${styles.zsmDrmMeetingList__mainBox}`} id={index}>
                                            <div className={styles.zsmDrmMeetingList__locationBox}>
                                                <div
                                                    className={styles.zsmDrmMeetingList__tag}
                                                    style={{ backgroundColor: "#A2D7AF" }}
                                                ></div>
                                                <div className={styles.zsmDrmMeetingList__location}>
                                                    <CiVideoOn
                                                        className={styles.zsmDrmMeetingList__locationIcon}
                                                    />
                                                    {/* <img src={meeting} className={styles.loader__loaderImg} /> */}
                                                    <p>
                                                        Meeting ID - {item.Id}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={styles.zsmDrmMeetingList__dateBox}>
                                                <div className={styles.zsmDrmMeetingList__tourDate}>
                                                    <p>{item.Actual_Date_of_the_Meet__c}</p>
                                                    <span className={styles.zsmDrmMeetingList__divideLine}>
                                                        |
                                                    </span>
                                                    <p className={styles.zsmDrmMeetingList__tourDay}
                                                        style={{
                                                            color:
                                                                item.NSM_Status__c === "Approved" ? "#A2D7AF" :
                                                                item.NSM_Status__c === "Pending" ? "#FFA500" :
                                                                item.NSM_Status__c === "Rejected" ? "#FF6347" : "#D3D3D3"
                                                        }}>
                                                        {item.NSM_Status__c}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className={styles.upcomingTourPlans__edit}>
                                                <HiOutlineArrowRight className={styles.upcomingTourPlans__locationIcon} />
                                            </div> */}
                                        </div>
                                    </div>
                                </>
                            ))}
                        <Link to={`/drm-meeting`}><button class="plus-button"></button></Link>
                    </Col>
                </div>
            </div>
        </>
    )
}

export default DrmZsmMeetingList