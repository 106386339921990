import axios from "axios";
import { BASE_URL } from "../../variables";

export const getMeetingAttachment = async ({ recordSFId }) => {

    const options = {
        url: `${BASE_URL}/services/apexrest/GetDRMAttachmentforZSM/${recordSFId}`,
        method: "get",
    };
    try {
        const response = await axios(options);
        if (response.status === 200) {
            return {
                success: true,
                data: response?.data,
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};
