import axios from "axios";
import { BASE_URL } from "../../variables";

export const getMultiSelectCustomerCodeApi = async (UserSH_Code__c, Title) => {
  console.log("test", UserSH_Code__c);

  let url = `${BASE_URL}/services/data/v56.0/query?q=SELECT  CustomerName__c, Customer_Code__c FROM Account WHERE RecordType.Name IN ('Customer', 'CAPScouted') 
            AND TSI_Territory_Code__c in ${UserSH_Code__c} AND DiscontinueFlag__c = false GROUP BY CustomerName__c, Customer_Code__c`;

  const options = {
    url: url,
    method: "get",
  };
  try {
    const response = await axios(options);
    console.log("res", response);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
