import axios from "axios";
import { BASE_URL } from "../../variables";

export const getMultiSelectTerritoryApi = async (UserSH_Code__c, Title) => {
  console.log("test", UserSH_Code__c);

  let url = `${BASE_URL}/services/data/v56.0/query?q=SELECT SH2_Code__c, SH2_Name__c FROM Pidilite_Hierarchy__c WHERE SH3_Code__c in ${UserSH_Code__c} AND RecordType.Name IN ('Sales Hierarchy', 'Key Information') GROUP BY SH2_Code__c, SH2_Name__c`;

  const options = {
    url: url,
    method: "get",
  };
  try {
    const response = await axios(options);
    console.log("res", response);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
