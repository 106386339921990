import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { Autocomplete, TextField, Button, Chip, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from "@mui/material";
import "./DrmZsmMeeting.css";
import _ from "lodash";
import { getMultiSelectTerritoryApi } from "../../../src/services/DRM/getMultiSelectTerritoryApi";
import PageHeader from "../Common/PageHeader";
import { formatDate, getUniqueListBy } from "../Common/Function";
import { getMultiSelectCustomerCodeApi } from "../../services/DRM/getMultiSelectCustomerCodeApi";
import { createZsmDrmMeetingApi } from "../../services/DRM/createZsmDrmMeetingApi";
import { updateZsmDrmMeetingApi } from "../../services/DRM/updateZsmDrmMeetingApi";
import ModalPopup from "../Common/ModalPopup";
import { RiDraftFill } from "react-icons/ri";
import { MdOutlineSmsFailed } from "react-icons/md";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loader from "../Common/Loader";
import { MdOutlineAttachment } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { getMeetingAttachment } from "../../services/DRM/drmMeetingAttachmentApi";
import { BiDownload } from "react-icons/bi";
import { getEmployeeIdApi } from "../../services/DRM/getEmployeeIdApi";


function DrmZsmMeeting() {
    const { control, handleSubmit, watch, reset, setValue } = useForm({
        defaultValues: {
            cluster: "",
            territory: "",
            wss: "",
            noOfDealersInvited: "",
            totalCost: "",
            primaryReason: "",
            costPerHead: "",
            UpdatedTotalCost: "",
            UpdatedCostperHead: "",
            budgetedExpenses: "",
            noOfDealersAttended: "",
            actualExpenses: "",
            budgetReason: "",
            OtherPrimaryReason: "",
            ActualDateOfTheMeet: null,
            TentativeDateOfTheMeet: null,
        },
    });

    const clusterCodeOption = useSelector((state) => state?.clusterCode?.clusterCodeList);
    const [selectedClusters, setSelectedClusters] = useState([]);
    const [territoryPickList, setTerritoryPickList] = useState([]);
    const [selectedTerritory, setSelectedTerritory] = useState([]);
    const [selectedWSS, setSelectedWSS] = useState([]);
    const [selectedTerritoryString, setSelectedTerritoryString] = useState({});
    const [wssOptionList, setwssOptionList] = useState([]);
    const Title = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);
    const selectedBudgetType = watch("primaryReason");
    const [showOtherBudget, setShowOtherBudget] = useState(false);
    const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const userEmail = useSelector((state) => state?.userDetails?.details?.Email);
    const [disable, setDisable] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [filesBase64, setFilesBase64] = useState([]);
    const { id } = useParams();
    const location = useLocation();
    const meetingDetails = location.state || null;
    const nsmStatus = meetingDetails?.NSM_Status__c;
    const [remark, setRemark] = useState("");
    const [attachmentData, setAttachmentData] = useState(null);
    const [error, setError] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [fileContent, setFileContent] = useState("")
    const [updatedFileUrl, setUpdatedFileUrl] = useState("");
    const [updatedFileName, setUpdatedFileName] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const zone = useSelector((state) => state?.userDetails?.details?.Division);
    const salesGroupCode = useSelector((state) => state?.userDetails?.details?.Sales_Group_Code__c);
    const [employeeId, setEmployeeId] = useState("");
    const userEmailid = useSelector((state) => state?.userDetails?.details?.Email);

    const totalCost = watch("totalCost");
    const noOfDealersInvited = watch("noOfDealersInvited");
    const UpdatedTotalCost = watch("UpdatedTotalCost");
    const noOfDealersAttended = watch("noOfDealersAttended");

    useEffect(() => {
        // Calculate cost per head for invited dealers
        if (totalCost && noOfDealersInvited && noOfDealersInvited > 0) {
            setValue("costPerHead", (totalCost / noOfDealersInvited).toFixed(2));
        } else {
            setValue("costPerHead", "");
        }

        // Calculate cost per head for attended dealers
        if (UpdatedTotalCost && noOfDealersAttended && noOfDealersAttended > 0) {
            setValue("UpdatedCostperHead", (UpdatedTotalCost / noOfDealersAttended).toFixed(2));
        } else {
            setValue("UpdatedCostperHead", "");
        }
    }, [totalCost, noOfDealersInvited, UpdatedTotalCost, noOfDealersAttended, setValue]);

    const parseData = (input, key1, key2) => {
        // Validate input: If null, undefined, or not a string, return an empty array
        if (!input || typeof input !== "string") {
            console.warn(`⚠️ Invalid input received for parsing:`, input);
            return [];
        }

        return input.split(", ").map(pair => {
            let [name, code] = pair.split(" : ");

            // Trim values and check if they exist
            name = name?.trim() || "Unknown";
            code = code?.trim() || "N/A";

            return {
                [key1]: name,
                [key2]: code
            };
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const result = await getEmployeeIdApi(userEmailid);
                console.log("Employee ID res", result)
                if (!_.isEmpty(result?.data?.records[0])) {
                    setEmployeeId(result?.data?.records[0].Employee_Code__c);
                }
            } catch (error) {
                setEmployeeId('');
                setLoading(false);
            }
        })();
    }, [userEmailid])

    useEffect(() => {
        if (!meetingDetails?.Id) return;

        const fetchAttachment = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await getMeetingAttachment({ recordSFId: meetingDetails.Id });
                if (response?.success) {
                    setAttachmentData(response?.data);
                } else {
                    setError(response?.msg);
                }
            } catch (err) {
                console.log("Something went wrong while fetching attachments.")
            } finally {
                setLoading(false);
            }
        };

        fetchAttachment();
    }, [meetingDetails?.Id]);

    const salesGroupMap = {
        "105": "FV",
        "301": "MNT",
        "401": "CC",
        "406": "ROFF",
        "901": "EI",
        "902": "EI"
    };

    const generateSharedId = (salesGroupCode, id) => {
        const prefix = salesGroupMap[salesGroupCode] || "UNKNOWN"; // Default if not found
        return `${prefix}${id}`;
    };

    useEffect(() => {
        if (meetingDetails) {
            if (meetingDetails.NSM_Status__c == "Approved" || meetingDetails.NSM_Status__c == "Pending") {
                setDisable(true);
            }
            if (meetingDetails.NSM_Status__c == "Completed") {
                setDisable(true);
                setIsDisable(true)
            }
            console.log("meetingDetails", meetingDetails)
            let cluster = parseData(meetingDetails.Cluster_Name__c, "SH3_Name__c", "SH3_Code__c") || [];
            let territory = parseData(meetingDetails.TSI_Territory_Name__c, "SH2_Name__c", "SH2_Code__c") || [];
            let wss = parseData(meetingDetails.Customer_Name__c, "CustomerName__c", "Customer_Code__c") || [];

            setSelectedClusters(cluster);
            setSelectedTerritory(territory);
            setSelectedWSS(wss);
            setRemark(meetingDetails?.NSM_Feedback__c);
            console.log("clusterArray", cluster)

            reset({
                cluster: meetingDetails.Cluster_Name__c || "",
                territory: meetingDetails.TSI_Territory_Name__c || "",
                wss: meetingDetails.Customer_Name__c || "",
                noOfDealersInvited: meetingDetails.No_of_Dealers_invited__c || "",
                totalCost: meetingDetails.Total_Cost__c || "",
                primaryReason: meetingDetails.Primary_Reason_for_DRM__c || "",
                costPerHead: meetingDetails.Cost_Per_Head__c || "",
                totalCost1: meetingDetails.Total_Cost__c || "",
                costPerHead1: meetingDetails.costPerHead1 || "",
                budgetedExpenses: meetingDetails.Budget_out_of_Pocket_expenses__c || "",
                noOfDealersAttended: meetingDetails.noOfDealersAttended || "",
                actualExpenses: meetingDetails.Actual_out_of_pocket_expenses__c || "",
                budgetReason: meetingDetails.Reason_for_exceeding_Budget__c || "",
                OtherPrimaryReason: meetingDetails.OtherPrimaryReason || "",
                ActualDateOfTheMeet: meetingDetails.Actual_Date_of_the_Meet__c || null,
                TentativeDateOfTheMeet: meetingDetails.Tentative_date_of_the_meet__c || null,
                UpdatedTotalCost: meetingDetails.Updated_Total_Cost__c || null,
                UpdatedCostperHead: meetingDetails.Updated_Cost_per_Head__c || null,
                noOfDealersAttended: meetingDetails.Actual_no_of_Dealers_Participated__c || ""
            });

            function formatDate(dateStr) {
                const date = new Date(dateStr);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
                const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
                return `${year}-${month}-${day} 00:00:00`;
            }

            const NextSpecaObj = {
                "Division": zone,
                "SalesGroup": salesGroupCode,
                "HierarchyCode": meetingDetails.TSI_Territory_Name__c,
                "VerticalName": salesGroupCode,
                "MeetingDate": formatDate(meetingDetails.Actual_Date_of_the_Meet__c),
                "MeetingType": "DRM (MANAGED BY FIELD)",
                "MeetingCode": generateSharedId(salesGroupCode, meetingDetails?.Id),
                "CityName": "",
                "TownCode": "UGJ00006", // 8 characters
                "PerHeadCost": meetingDetails.Updated_Cost_per_Head__c,
                "InviteeCount": Number(`${meetingDetails.No_of_Dealers_invited__c}`), /// validation 0..00
                "AttendeeCount": Number(`${meetingDetails.noOfDealersAttended}`), /// validation 0..00
                "TotalAmount": meetingDetails.Updated_Total_Cost__c,
                "AttendeesPhoto": "()",
                "Billno": "0",
                "ITrakToTEMFlag": "Y",
                "ITrakToTEMDate": "2024-01-02 00:00:00",
                "Attendee_KYCRecieved": 0,
                "Attendee_KYCNotRecieved": 0,
                "Attendee_Stakeholders": 0,
                "Attendee_Helper": 0,
                "Attendee_Repeat": "0",
                "MeetingFormat": "Physical Meet",
                "ADV_AND_PROMO": "",
                "EMPLOYEECODE": employeeId
            }
            console.log("NextSpecaObj", NextSpecaObj)
        }
    }, [meetingDetails])


    const handleFileUpload = (event) => {
        const files = event.target.files;
        const fileArray = Array.from(files);
        const promises = [];

        fileArray.forEach((file) => {
            const reader = new FileReader();
            const promise = new Promise((resolve) => {
                reader.onload = (e) => {
                    resolve({
                        name: file.name,
                        base64: e.target.result.split(",")[1], // Extract Base64 part
                    });
                };
            });
            reader.readAsDataURL(file);
            promises.push(promise);
        });

        Promise.all(promises).then((results) => {
            setFilesBase64(results);
            console.log("Base64 Data:", results);
        });
    };

    // to get territoy using multiple cluster code
    useEffect(() => {
        // const selectedClusters = watch("cluster");
        console.log("selectedClusters", selectedClusters)
        if (selectedClusters.length > 0) {
            const sh3Codes = `(${selectedClusters.map(item => `'${item.SH3_Code__c}'`).join(", ")})`;
            console.log("sh3Codes", sh3Codes);
            (async () => {
                const response = await getMultiSelectTerritoryApi(sh3Codes, Title);
                console.log("res", response)
                if (response?.success) {
                    setTerritoryPickList(response?.data?.records);
                } else {
                    setTerritoryPickList("");
                }
            })();
        }
    }, [selectedClusters]);

    // To get Customer code using multiple Territory code
    useEffect(() => {
        console.log("selectedTerritory", selectedTerritory)
        if (selectedTerritory.length > 0) {
            const sh2Codes = `(${selectedTerritory.map(item => `'${item.SH2_Code__c}'`).join(", ")})`;
            console.log("sh2Codes", sh2Codes);
            (async () => {
                const response = await getMultiSelectCustomerCodeApi(sh2Codes, Title);
                console.log("res", response)
                if (response?.success) {
                    setwssOptionList(response?.data?.records);
                } else {
                    setwssOptionList("");
                }
            })();
        }
    }, [selectedTerritory]);

    const onSubmit = async (data) => {
        let errors = {};

        // Manual validation
        if (!data.cluster) errors.cluster = "Cluster is required";
        if (!data.territory) errors.territory = "Territory is required";
        if (!data.wss) errors.wss = "WSS is required";
        if (!data.noOfDealersInvited) errors.noOfDealersInvited = "No of dealers invited is required";
        if (!data.totalCost) errors.totalCost = "Total cost is required";
        if (!data.primaryReason) errors.primaryReason = "Primary reason is required";
        if (!data.ActualDateOfTheMeet) errors.ActualDateOfTheMeet = "Actual Date is required";
        if (!data.costPerHead) errors.costPerHead = "Cost Per Head is required";
        if (!data.budgetedExpenses) errors.budgetedExpenses = "Budgeted Expenses is required";
        if (!data.TentativeDateOfTheMeet) errors.TentativeDateOfTheMeet = "Tentative Date is required";
        if ((!filesBase64 || filesBase64.length === 0) && !fileContent) {
            errors.filesBase64 = "Attachment is required";
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        setLoading(true);
        const isUpdate = meetingDetails?.Id ? true : false;
        console.log("isUpdate", isUpdate, meetingDetails)

        const payload = {
            "DRM": [
                {
                    "id": meetingDetails?.Id ?? null,
                    "SG": "SG2",
                    "Zone": "Zone222",
                    "ClusterName": data?.cluster ?? "",
                    "TSITerritoryName": data?.territory ?? "",
                    "TownName": "Town A",
                    "CustomerName": data?.wss ?? "",
                    "UserEmail": userEmail,
                    "isZSMCreated": true,
                    "PrimaryReasonForDRM": data?.primaryReason ?? "",
                    "NoOfDealersInvited": data?.noOfDealersInvited ? parseInt(data.noOfDealersInvited) : 0,
                    "ReasonForExceedingBudget": data?.budgetReason ?? "",
                    "ActualOutOfPocketExpenses": data?.actualExpenses ? parseFloat(data.actualExpenses) : 0,
                    "BudgetOutOfPocketExpenses": data?.budgetedExpenses ? parseFloat(data.budgetedExpenses) : 0,
                    "TotalCost": data?.totalCost ? parseFloat(data.totalCost) : 0,
                    "CostPerHead": data?.costPerHead ? parseFloat(data.costPerHead) : 0,
                    "ZSMStatus": meetingDetails?.NSM_Status__c === "Approved" ? "Completed" : meetingDetails?.ZSM_Status__c ? meetingDetails?.ZSM_Status__c : "",
                    "NSMStatus": meetingDetails?.NSM_Status__c === "Approved" ? "Completed" : meetingDetails?.NSM_Status__c === "Rejected" ? meetingDetails?.NSM_Status__c : "",
                    "NSM_Feedback__c": "",
                    "ActualDateOfTheMeet": data?.ActualDateOfTheMeet ? formatDate(data.ActualDateOfTheMeet) : null,
                    "TentativeDateOfTheMeet": data?.TentativeDateOfTheMeet ? formatDate(data.TentativeDateOfTheMeet) : null,
                    "UpdatedTotalCost": data?.UpdatedTotalCost ? parseFloat(data.UpdatedTotalCost) : 0,
                    "UpdatedCostperHead": data?.UpdatedCostperHead ? parseFloat(data.UpdatedCostperHead) : 0,
                    "ActuaNoOfDealersParticipated": data?.noOfDealersAttended ? parseFloat(data.noOfDealersAttended) : 0,
                    "fileName": filesBase64.length > 0 && filesBase64[0]?.name ? filesBase64[0]?.name : fileContent?.fileName,
                    "fileContent": filesBase64.length > 0 && filesBase64[0]?.base64 ? filesBase64[0].base64 : fileContent?.base64Data,
                    "fileType": "xlsx",
                    "isListOfDealersAndEmployeesAttended": meetingDetails?.Id ? meetingDetails?.NSM_Status__c === "Rejected" ? false : meetingDetails?.NSM_Status__c === "Approved" ? true : false : undefined,
                }
            ]
        };

        try {
            let response;
            if (isUpdate) {
                response = await updateZsmDrmMeetingApi(payload);
            } else {
                response = await createZsmDrmMeetingApi(payload);
            }
            if (response?.success) {
                setFormErrors({})
                setIsDraftModalOpen(true);
                reset({
                    cluster: "",
                    territory: "",
                    wss: "",
                    noOfDealersInvited: "",
                    totalCost: "",
                    primaryReason: "",
                    costPerHead: "",
                    totalCost1: "",
                    costPerHead1: "",
                    budgetedExpenses: "",
                    noOfDealersAttended: "",
                    actualExpenses: "",
                    budgetReason: "",
                    OtherPrimaryReason: "",
                    ActualDateOfTheMeet: null,
                    TentativeDateOfTheMeet: null,
                    UpdatedTotalCost: "",
                    UpdatedCostperHead: "",
                });
                setFilesBase64([]);
                setSelectedClusters([]);
                setSelectedTerritory([]);
                setSelectedWSS([]);
                setRemark("");
                setLoading(false);
            } else {
                setIsErrorModalOpen(true);
                setLoading(false);
            }
        } catch (error) {
            console.error("API Error:", error);
            setLoading(false);
        }
        console.log("Form Data:", data, payload);
    };

    useEffect(() => {
        console.log("Updated Form Data:", watch());
    }, [watch()]);

    const handleDateChange = (name, newValue) => {
        setValue(name, newValue, { shouldValidate: true });
    };

    useEffect(() => {
        if (attachmentData?.created?.length > 0) {
            const file = attachmentData?.created[0];
            setFileContent(file?.fileName || "MeetingAttachment.xlsx");

            // Convert Base64 to Blob
            const byteCharacters = atob(file.base64Data);
            const byteNumbers = new Uint8Array([...byteCharacters].map(char => char.charCodeAt(0)));
            const blob = new Blob([byteNumbers], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

            // Create download link
            const url = URL.createObjectURL(blob);
            console.log("\\\\\\\\\\\\\\\\\\", url)
            setFileUrl(url);
        }

        if (attachmentData?.updated?.length > 0) {
            const file = attachmentData?.updated[0];
            setUpdatedFileName(file.fileName || "UpdatedAttachment.xlsx");

            // Convert Base64 to Blob
            const byteCharacters = atob(file.base64Data);
            const byteNumbers = new Uint8Array([...byteCharacters].map(char => char.charCodeAt(0)));
            const blob = new Blob([byteNumbers], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

            // Create download link
            const url = URL.createObjectURL(blob);
            setUpdatedFileUrl(url);
        }
    }, [attachmentData]);


    const handleDownload = (fileUrl, fileName) => {
        if (fileUrl) {
            const link = document.createElement("a");
            link.href = fileUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    //   const handleSpecaSubmit = async (e) => {
    //     //// format current time yyyy-MM-dd HH:mm:ss in this format ////
    //     const convertDateToSpecaFormat = (originalDate) => {
    //       const utcYear = originalDate.getUTCFullYear();
    //       const utcMonth = originalDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
    //       const utcDay = originalDate.getUTCDate();
    //       const utcHours = originalDate.getUTCHours();
    //       const utcMinutes = originalDate.getUTCMinutes();
    
    //       // Format the date into "YYYY-MM-DD HH:mm:ss.SSSZ"
    //       const formattedDate = `${utcYear}-${pad(utcMonth)}-${pad(utcDay)} ${pad(utcHours)}:${pad(utcMinutes)}:00.000Z`;
    
    
    
    //       // Function to pad single digit numbers with leading zeros
    //       function pad(number) {
    //         if (number < 10) {
    //           return '0' + number;
    //         }
    //         return number;
    //       }
    //       return formattedDate
    //     }
    //     /// convert current local time to utc ///
    //     const now = new Date();
    //     const CurrentDate = convertDateToSpecaFormat(now);
    //     /// convert Actual date of meet to utc ///
    //     const date = new Date(formData?.ActualDateOfTheMeet);
    //     const MeetingDate = convertDateToSpecaFormat(date);
    
    
    //     //// trim the town code from townname string ///
    //     const originalString = formData?.TownName
    //     const townCode = originalString.split(':')[1].trim();
    
    
    
    //     ///// update current speca object with formated values /// 
    //     const NextSpecaObj = {
    //       ...specaObj,
    //       "MeetingDate": MeetingDate,
    //       "ITrakToTEMDate": CurrentDate,
    //       "TownCode": townCode
    //     };
    
    
    //     if (validateForm() && validateFile()) {
    //       try {
    //         setLoading(true)
    //         const response = await SPECASubmit({ NextSpecaObj })
    //         if (response?.success == true) {
    //           const actualDate = formData?.TentativeDateOfTheMeet;
    //           const formattedDate = actualDate ? format(new Date(actualDate), 'yyyy-MM-dd') : undefined;
    
    //         //   const formDataWithId = {
    //         //     ...formData,
    //         //     id: meetingdetails?.Id,
    //         //     TentativeDateOfTheMeet: formattedDate,
    //         //     ActualDateOfTheMeet: formattedDate,
    //         //     NSMStatus: "Completed",
    //         //     ZSMStatus: "Completed",
    //         //     Images: file
    
    //         //   }
    //         //   const updateresponse = await UpdateMeeting({ formDataWithId })
    //         //   if (updateresponse?.success == true) {
    //         //     setDisableSubmitDRM(true)
    //         //     setLoading(false)
    //         //     setSpecaModal(true)
    //         //   }
    //         //   else {
    //         //     setFailesOpen(true)
    //         //     setSnackError("something went wrong.. try again after some time or contact your admin for further assistance..")
    //         //     setLoading(false)
    
    //         //   }
    //         }
    //         else {
    //           // setSnackError(response?.msg)
    //           setFailesOpen(true)
    //           setSnackError("something went wrong.. try again after some time or contact your admin for further assistance..")
    //           setLoading(false)
    //         }    
    //       } catch (error) {
    //         console.error("Error during API call:", error);
    //         setFailesOpen(true)
    //         setSnackError("something went wrong..")
    //         setLoading(false)
    //       }
    //     }
    //   }


    return (
        <>
            <PageHeader title="Create DRM Meeting" path="/drm-planned-meeting" />
            {remark && (
                <>
                    <div className="drmZmsMeeting__remarkBox">
                        <label className="drmZmsMeeting__remarkLable"><b>NSM Remark:</b></label>
                        <label className="drmZmsMeeting__remark">{remark}</label>
                    </div>
                </>
            )}
            <div className="drmZsmMeeting container mt-0">
                <Loader loading={loading} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* First Half */}
                    <div className="first-half">
                        <label className="drmZmsMeeting__selectLabel">Select Cluster</label>
                        <Controller
                            name="cluster"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    multiple
                                    options={
                                        !clusterCodeOption ||
                                            clusterCodeOption?.length === 0
                                            ? [
                                                {
                                                    SH3_Name__c: "Loading...",
                                                    SH3_Code__c: ""
                                                }
                                            ]
                                            : getUniqueListBy(
                                                clusterCodeOption,
                                                "SH3_Name__c"
                                            )
                                    }
                                    className="drmZmsMeeting__inputMultiple"
                                    getOptionLabel={(option) => `${option.SH3_Name__c} : ${option.SH3_Code__c}`}
                                    value={selectedClusters}
                                    onChange={(event, newValue) => {
                                        setSelectedClusters(newValue);
                                        const formattedValue = newValue
                                            .map((option) => `${option.SH3_Name__c} : ${option.SH3_Code__c}`)
                                            .join(", ");
                                        field.onChange(formattedValue);
                                    }}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip key={index} {...getTagProps({ index })} label={`${option.SH3_Name__c} : ${option.SH3_Code__c}`} />
                                        ))
                                    }
                                    renderInput={(params) => <TextField {...params} placeholder="Select Cluster" />}
                                    disabled={disable}
                                />
                            )}
                        />
                        {formErrors.cluster && <p className="error_masg">{formErrors.cluster}</p>}
                        <label className="drmZmsMeeting__selectLabel">Select Territory</label>
                        <Controller
                            name="territory"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    multiple
                                    options={territoryPickList}
                                    className="drmZmsMeeting__inputMultiple"
                                    getOptionLabel={(option) => `${option.SH2_Name__c} : ${option.SH2_Code__c}`}
                                    value={selectedTerritory}
                                    onChange={(event, newValue) => {
                                        setSelectedTerritory(newValue);
                                        const formattedValue = newValue
                                            .map((option) => `${option.SH2_Name__c} : ${option.SH2_Code__c}`)
                                            .join(", ");
                                        field.onChange(formattedValue);
                                    }}
                                    //isOptionEqualToValue={(option, value) => option.value === value.value} // Fix comparison logic
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip key={index} {...getTagProps({ index })} label={`${option.SH2_Name__c} : ${option.SH2_Code__c}`} />
                                        ))
                                    }
                                    renderInput={(params) => <TextField {...params} placeholder="Select Territory" />}
                                    disabled={disable}
                                />
                            )}
                        />
                        {formErrors.territory && <p className="error_masg">{formErrors.territory}</p>}
                        <label className="drmZmsMeeting__selectLabel">Select WSS</label>
                        <Controller
                            name="wss"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    multiple
                                    options={wssOptionList}
                                    className="drmZmsMeeting__inputMultiple"
                                    getOptionLabel={(option) => `${option.CustomerName__c} : ${option.Customer_Code__c}`}
                                    value={selectedWSS}
                                    onChange={(event, newValue) => {
                                        setSelectedWSS(newValue);
                                        const formattedValue = newValue
                                            .map((option) => `${option.CustomerName__c} : ${option.Customer_Code__c}`)
                                            .join(", ");
                                        field.onChange(formattedValue);
                                    }}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip key={index} {...getTagProps({ index })} label={`${option.CustomerName__c} : ${option.Customer_Code__c}`} />
                                        ))
                                    }
                                    renderInput={(params) => <TextField {...params} placeholder="Select WSS" />}
                                    disabled={disable}
                                />
                            )}
                        />
                        {formErrors.wss && <p className="error_masg">{formErrors.wss}</p>}
                        <div>
                            <Controller
                                name="primaryReason"
                                control={control}
                                defaultValue="longGap"
                                render={({ field }) => (
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend" className="drmZmsMeeting__selectLabel">Primary reason for DRM</FormLabel>
                                        <RadioGroup {...field} row>
                                            <FormControlLabel value="longGap" className="drmZmsMeeting__selectRadio" control={<Radio />} label="Long gap since last DRM" disabled={disable} />
                                            <FormControlLabel value="competition-counteraction" className="drmZmsMeeting__selectRadio" control={<Radio />} label="Competition counteraction" disabled={disable} />
                                            <FormControlLabel value="new-product/initiative" className="drmZmsMeeting__selectRadio" control={<Radio />} label="Launch of new product/initiative" disabled={disable} />
                                            <FormControlLabel value="focus-market" className="drmZmsMeeting__selectRadio" control={<Radio />} label="Focus market" disabled={disable} />
                                            <FormControlLabel value="other" className="drmZmsMeeting__selectRadio" control={<Radio />} label="Other" disabled={disable} />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                        </div>
                        {selectedBudgetType === "other" && (
                            <>
                                <label className="drmZmsMeeting__selectLabel">Other Primary Reason</label>
                                <Controller
                                    name="OtherPrimaryReason"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField {...field} className="drmZmsMeeting__textfield" fullWidth margin="dense" disabled={disable} />
                                    )}
                                />
                            </>
                        )}
                        {formErrors.primaryReason && <p className="error_masg">{formErrors.primaryReason}</p>}
                        {/* Other Input Fields */}
                        <label className="drmZmsMeeting__selectLabel">No. of Dealers Invited</label>
                        <Controller name="noOfDealersInvited" control={control}
                            render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={disable} />} />

                        <div>
                            {formErrors.noOfDealersInvited && <p className="error_masg">{formErrors.noOfDealersInvited}</p>}

                            <label className="drmZmsMeeting__selectLabel">List of dealer Invited</label><br />
                            <div>
                                {error && <p style={{ color: "red" }}>{error}</p>}

                                {(attachmentData?.created?.length > 0 && meetingDetails?.NSM_Status__c !== "Rejected") ? (
                                    <div className="attachmentWrapper row">

                                        <div className="text-end align-middle">
                                            <p className="drmZmsMeeting__selectLabel "><strong>Attachment:</strong> {fileContent}</p>
                                        </div>
                                        <div className="text-end align-middle">
                                            <Button
                                                type="button"
                                                variant="contained"
                                                className="newFeedback__submitBtn"
                                                onClick={() => handleDownload(fileUrl, fileContent)}
                                            >
                                                Download
                                                <BiDownload
                                                    style={{
                                                        marginLeft: "4px",
                                                        height: "1em",
                                                        width: "1.1em",
                                                    }}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="drmZmsMeeting__inputWrapper">
                                        <div className="drmZmsMeeting__selectLabel">
                                            {filesBase64.length > 0 && filesBase64[0]?.name ? filesBase64[0].name : ""}
                                        </div>
                                        <MdOutlineAttachment className="drmZmsMeeting__attachIcon" />
                                        <label className="drmZmsMeeting__inputLabel">
                                            Attach File
                                            <input
                                                className="drmZmsMeeting__inputFile"
                                                type="file"
                                                accept=".xls,.xlsx"
                                                multiple
                                                // key={formData.marketVisitDate} 
                                                onChange={handleFileUpload}
                                                // disabled={disable}
                                                disabled={disable}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>
                            {formErrors.filesBase64 && <p className="error_masg d-flex justify-content-end ">{formErrors.filesBase64}</p>}

                        </div>

                        <label className="drmZmsMeeting__selectLabel">Total Cost</label>
                        <Controller name="totalCost" control={control}
                            render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={disable} />} />
                        {formErrors.totalCost && <p className="error_masg">{formErrors.totalCost}</p>}

                        <label className="drmZmsMeeting__selectLabel">Cost Per Head</label>
                        <Controller name="costPerHead" control={control}
                            render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={disable} />} />
                        {formErrors.costPerHead && <p className="error_masg">{formErrors.costPerHead}</p>}

                        <label className="drmZmsMeeting__selectLabel">Budgeted Out-of-Pocket Expenses</label>
                        <Controller name="budgetedExpenses" control={control}
                            render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={disable} />} />
                        {formErrors.budgetedExpenses && <p className="error_masg">{formErrors.budgetedExpenses}</p>}

                    </div>
                    <label className={"drmZmsMeeting__selectLabel"}>
                        Tentative Date of the Meet
                    </label>
                    <Box className="myDatePicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    height: "1.7rem",
                                    marginBottom: "0.8rem",
                                    border: "0.692008px solid #808080",
                                    borderRadius: "0.3rem",
                                    color: "#808080",
                                }}
                                value={watch("TentativeDateOfTheMeet") ? dayjs(watch("TentativeDateOfTheMeet")) : null}
                                onChange={(newValue) => handleDateChange("TentativeDateOfTheMeet", newValue)}
                                disabled={disable}
                            />
                        </LocalizationProvider>
                    </Box>
                    {formErrors.TentativeDateOfTheMeet && <p className="error_masg">{formErrors.TentativeDateOfTheMeet}</p>}
                    <label className={"drmZmsMeeting__selectLabel"}>
                        Actual Date of the Meet
                    </label>
                    <Box className="myDatePicker drmZmsMeeting__date">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{
                                    width: "100%",
                                    whiteSpace: "nowrap",
                                    height: "1.7rem",
                                    marginBottom: "0.8rem",
                                    border: "0.692008px solid #808080",
                                    borderRadius: "0.3rem",
                                    color: "#808080",
                                }}
                                value={watch("ActualDateOfTheMeet") ? dayjs(watch("ActualDateOfTheMeet")) : null}
                                onChange={(newValue) => handleDateChange("ActualDateOfTheMeet", newValue)}
                                disabled={disable}
                            />
                        </LocalizationProvider>
                    </Box>
                    {formErrors.ActualDateOfTheMeet && <p className="error_masg">{formErrors.ActualDateOfTheMeet}</p>}
                    {/* Conditional Second Half */}
                    {(nsmStatus === "Approved" || nsmStatus === "Completed") && (
                        <>
                            <label className="drmZmsMeeting__selectLabel">No. of Dealers Attended</label>
                            <Controller name="noOfDealersAttended" control={control}
                                render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={isDisable} />} />

                            <div>
                                {/* <input type="file" accept=".xls,.xlsx" multiple onChange={handleFileUpload} /> */}
                                <label className="drmZmsMeeting__selectLabel">List of dealers + employees attended</label><br />
                                {(attachmentData?.updated?.length > 0 && meetingDetails?.NSM_Status__c !== "Rejected") ? (
                                    <div className="attachmentWrapper row">

                                        <div className="text-end align-middle">
                                            <p className="drmZmsMeeting__selectLabel "><strong>Attachment:</strong> {updatedFileName}</p>
                                        </div>
                                        <div className="text-end align-middle">
                                            <Button
                                                type="button"
                                                variant="contained"
                                                className="newFeedback__submitBtn"
                                                onClick={() => handleDownload(updatedFileUrl, updatedFileName)}
                                            >
                                                Download
                                                <BiDownload
                                                    style={{
                                                        marginLeft: "4px",
                                                        height: "1em",
                                                        width: "1.1em",
                                                    }}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="drmZmsMeeting__inputWrapper">
                                        <div className="drmZmsMeeting__selectLabel">
                                            {filesBase64?.length > 0 && filesBase64[0]?.name ? filesBase64[0].name : ""}
                                        </div>
                                        <MdOutlineAttachment
                                            style={{
                                                height: "1.5em",
                                                width: "1.5em",
                                                color: "#2cc26a",
                                                marginRight: "5px",
                                            }}
                                        />
                                        <label className="drmZmsMeeting__inputLabel">
                                            Attach File
                                            <input
                                                className="drmZmsMeeting__inputFile"
                                                type="file"
                                                accept=".xls,.xlsx"
                                                multiple
                                                // key={formData.marketVisitDate} 
                                                onChange={handleFileUpload}
                                            // disabled={disable}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>

                            <label className="drmZmsMeeting__selectLabel">Total Cost</label>
                            <Controller name="UpdatedTotalCost" control={control}
                                render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={isDisable} />} />

                            <label className="drmZmsMeeting__selectLabel">Cost Per Head</label>
                            <Controller name="UpdatedCostperHead" control={control}
                                render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={isDisable} />} />

                            <label className="drmZmsMeeting__selectLabel">Actual Out-of-Pocket Expenses</label>
                            <Controller name="actualExpenses" control={control}
                                render={({ field }) => <TextField className="drmZmsMeeting__textfield" type="number" {...field} fullWidth disabled={isDisable} />} />

                            <label className="drmZmsMeeting__selectLabel">Reason for Exceeding Budget</label>
                            <Controller name="budgetReason" control={control}
                                render={({ field }) => <TextField className="drmZmsMeeting__textfield" {...field} fullWidth disabled={isDisable} />} />
                        </>
                    )}
                    {nsmStatus !== "Approved" && nsmStatus !== "Rejected" && nsmStatus !== "Completed" && (
                        <Button type="submit" variant="contained" style={{ marginTop: "10px" }} className={"drmZmsMeeting__submitButton"} disabled={disable}>
                            Create Meeting
                        </Button>
                    )}

                    {(nsmStatus === "Approved" || nsmStatus === "Rejected") && (
                        <Button type="submit" variant="contained" style={{ marginTop: "10px" }} className={"drmZmsMeeting__submitButton"} disabled={isDisable}>
                            Update Meeting
                        </Button>
                    )}

                    {nsmStatus === "Completed" && (
                        <Button variant="contained" style={{ marginTop: "10px" }} className={"drmZmsMeeting__submitButton"} disable> 
                            Submit DRM
                        </Button>
                    )}

                </form>
            </div>
            {isDraftModalOpen && (
                <ModalPopup
                    className="ModalPopup_Submitted"
                    isModalOpen={isDraftModalOpen}
                    setIsModalOpen={setIsDraftModalOpen}
                    title="Submitted Successfully"
                    data="DRM record has been saved successfully."
                    backdrop={true}
                    fade={false}
                    icon={
                        <>
                            <div className="submittedIcons draftIcons">
                                <RiDraftFill className="draftSmall" />
                                <RiDraftFill className="draftLarge" />
                                <RiDraftFill className="draftSmall" />
                            </div>
                        </>
                    }
                />
            )}
            {isErrorModalOpen && (
                <ModalPopup
                    className="ModalPopup_Submitted"
                    isModalOpen={isErrorModalOpen}
                    setIsModalOpen={setIsErrorModalOpen}
                    title="Failed"
                    data="Something went to wrong, Please try Again."
                    backdrop={true}
                    fade={false}
                    icon={
                        <>
                            <div className="submittedIcons errorIcons">
                                <MdOutlineSmsFailed className="errorSmall" />
                                <MdOutlineSmsFailed className="errorLarge" />
                                <MdOutlineSmsFailed className="errorSmall" />
                            </div>
                        </>
                    }
                />
            )}
        </>
    );
}

export default DrmZsmMeeting;
