//Filter Function to remove redundant data

import imageCompression from "browser-image-compression";
import { monthNames } from "./Constant";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";
import { SessionOutAlert } from "../../Redux/actions/sessionOut-action";

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

//Function to convert num to words
export function convertNumToWords(num) {
  let result;
  num = Math.floor(num);
  if (num == 0) {
    return "0";
  }
  const numLength = num?.toString()?.split("")?.length;
  if (numLength >= 4 && numLength <= 5) {
    result = (num / 1000).toFixed(2) + " " + "K";
    return result;
  } else if (numLength >= 6 && numLength <= 7) {
    result = (num / 100000).toFixed(2) + " " + "Lakh";
    return result;
  } else if (numLength >= 8 && numLength <= 9) {
    result = (num / 10000000).toFixed(2) + " " + "Cr";
    return result;
  } else if (numLength >= 10 && numLength <= 11) {
    result = (num / 10000000).toFixed(2) + " " + "Cr";
    return result;
  }
}

//Function to get first date of the past three month
//Check it - not in use
export function getPastThreeMonthsDate() {
  let date = [];
  for (let i = 0; i < 3; i++) {
    const current = new Date();
    current.setMonth(current.getMonth() - i);
    current.toLocaleDateString();
    date.push(current.toLocaleDateString());
  }
  return date;
}

//Function to get Past three month from the current month
export function getPastThreeMonths() {
  let months = [];
  for (let i = 0; i < 3; i++) {
    const current = new Date();
    // current.setMonth(current.getMonth() - i);
    // months.push(
    //   current.toLocaleString("default", {
    //     month: "long",
    //   })
    // );
    months.push(monthNames[current.getMonth() - i]);
  }
  return months;
}

//Function to get the last date of the current month
export function getLastDateOfCurrentMonth() {
  const date = new Date();
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).toLocaleDateString("en-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  return lastDay;
}

//Function to get the last date of the past three month
export function getLastDateOfPastThreeMonths() {
  let currentDate = new Date();
  let results = [];

  for (let i = 1; i <= 3; i++) {
    let lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i + 1,
      0
    ).toLocaleDateString("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    results.push(lastDay);
  }
  return results;
}

//Function to get the first date of the past three month
export function getFirstDayOfPastThreeMonth() {
  let currentDate = new Date();
  let results = [];

  for (let i = 1; i <= 3; i++) {
    let firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    ).toLocaleDateString("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    results.push(firstDay);
  }
  return results;
}

//Function  to get the first date of the current month
export function getFirstDateOfCurrentMonth() {
  let currentDate = new Date();
  let firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).toLocaleDateString("en-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  return firstDay;
}

// get first date and last date of current quarter
export function getCurrentQuarterDates() {
  var now = new Date();
  let quarter = Math.floor(now.getMonth() / 3);
  var firstDate = new Date(now.getFullYear(), quarter * 3, 1);
  var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
  firstDate = firstDate.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  endDate = endDate.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  return [firstDate, endDate];
}

// get first date and last date of past three quarters
export function getPastThreeQuarterDates() {
  let firstDates = getFirstDayOfPastThreeQuarter();
  let lastDates = [];
  firstDates.forEach((date) => {
    let firstDate = new Date(date);
    let endDate = new Date(
      firstDate.getFullYear(),
      firstDate.getMonth() + 3,
      0
    );
    firstDate = firstDate.toLocaleDateString("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    endDate = endDate.toLocaleDateString("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    lastDates.push(endDate);
  });

  return { firstDates, lastDates };
}

function getFirstDayOfPastThreeQuarter() {
  let currentDate = new Date();
  let results = [];

  for (let i = 1; i <= 3; i++) {
    let firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i * 3,
      1
    ).toLocaleDateString("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    results.push(firstDay);
  }
  return results;
}

// get current year
// export function getTodaysDate() {
//   const date = new Date()?.toLocaleDateString("en-GB", {
//     year: "numeric",
//   });
//   return date;
// }

// Get todays date

export function getCurrentDate() {
  const date = new Date()?.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  return date;
}

// function to get tomorrow's date
export function getYesterdayDate() {
  let today = new Date();
  today.setDate(today.getDate() - 1);
  return today?.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

// get last past five dates
export function getPastFiveDates() {
  let currentDate = new Date();
  let results = [];

  for (let i = 1; i <= 5; i++) {
    currentDate.setDate(currentDate.getDate() - 1);
    let date = currentDate.toLocaleDateString("en-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    results.push(date);
  }
  return results;
}

// get one day before date
export function getOneDayBeforeDate() {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  let date = currentDate.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  return date;
}

// to get Current Date
export function getTodaysDate() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const compressImage = async (file) => {
  const options = {
    maxSizeMB: 1,
    alwaysKeepResolution: true,
    useWebWorker: true,
  };
  const compressImg = await imageCompression(file, options);
  return compressImg;
};

export function arrayData(data) {
  let result = "";
  if (data?.length <= 1) {
    result = "'" + data + "'";
  } else {
    for (let i = 0; i < data.length; i++) {
      if (i != data?.length - 1) {
        result = result + "'" + data[i] + "'" + ",";
      } else {
        result = result + "'" + data[i] + "'";
      }
    }
  }
  return result;
}

// To check string valid or not for raising SR from MVR form

export function isValidString(str) {
  if (str === null || str === undefined) {
    return false; // or handle as per your logic if str is null or undefined
  }

  const invalidStrings = ["na", "n", "no", "no-issues", "no issue", "none"];
  const lowerCaseStr = str.toLowerCase().trim(); // Ensure toLowerCase() is called after null check

  return !invalidStrings.includes(lowerCaseStr);
}


export function checkSessionExpired(expiryTime, dispatch) {
  if (expiryTime) {
    const creationDateTime = new Date(expiryTime);
    const newDateTime = new Date(creationDateTime.getTime() + 24 * 60 * 60 * 1000);
    const currentDateTime = new Date();
    const fifteenMinutesInMilliseconds = 15 * 60 * 1000;
    if (currentDateTime > newDateTime) {
      sessionExpireTimeout(dispatch);
      dispatch(SessionOutAlert(""))
    } else {
      const timeDifference = newDateTime - currentDateTime;
      if (timeDifference <= fifteenMinutesInMilliseconds) {
        dispatch(SessionOutAlert(true))
      }
    }
  }
}


// Function to initialize event listeners and handle events
export function initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners) {
  let isCheckingSession = false;
  let hasEnteredCondition = false;

  const handleVisibilityChange = () => {
    const currentDateTime = new Date();
    const creationDateTime = new Date(expiryTime);
    const newDateTime = new Date(creationDateTime.getTime() + 24 * 60 * 60 * 1000);
    if (document.hidden) {
      // console.log('App is in the background');
    } else {
      isCheckingSession = true;
      if (currentDateTime < newDateTime || !hasEnteredCondition) {
        checkSessionExpired(expiryTime, dispatch);
        hasEnteredCondition = true;
      }
      isCheckingSession = false;
    }
  };

  const handleEvent = (event) => {
    if (eventCountRef.current < 5) {
      checkSessionExpired(expiryTime, dispatch);
      eventCountRef.current++;
    }

    if (eventCountRef.current >= 5) {
      cleanup();
    }
  };

  const cleanup = () => {
    window.removeEventListener('click', handleEvent);
    window.removeEventListener('scroll', handleEvent);
    hasEventListeners.current = false;
  };

  if (!hasEventListeners.current) {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('click', handleEvent);
    window.addEventListener('scroll', handleEvent);
    hasEventListeners.current = true;
  }

  // Cleanup event listeners
  return cleanup;
}

export function getSpecifiedFormattedArray(data, key) {
  return Array.isArray(data) ? data.map(item => item[key]).filter(Boolean) : [];
}

export function formatDate(isoString) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
  const day = String(date.getDate()).padStart(2, "0"); // Ensure 2-digit day
  return `${year}-${month}-${day}`;
};
